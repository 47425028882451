import {
  HOME_CLIENT_PROJECTS_BEGIN,
  HOME_CLIENT_PROJECTS_SUCCESS,
  HOME_CLIENT_PROJECTS_FAILURE,
  HOME_CLIENT_PROJECTS_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeaderWithParams } from '../../common/utils/auth';

export function clientProjects(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CLIENT_PROJECTS_BEGIN,
    });
    const { page, size } = args;
    const params = {
      page,
      size,
    };

    const options = builderAuthHeaderWithParams(params);
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/`,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CLIENT_PROJECTS_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_CLIENT_PROJECTS_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>clientProjects', err);
        },
      );
    });

    return promise;
  };
}

export function dismissClientProjectsError() {
  return {
    type: HOME_CLIENT_PROJECTS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CLIENT_PROJECTS_BEGIN:
      return {
        ...state,
        clientProjectsPending: true,
        clientProjectsError: null,
      };

    case HOME_CLIENT_PROJECTS_SUCCESS:
      return {
        ...state,
        clientProjectsPending: false,
        clientProjectsError: null,
        clientProjects: action.data,
      };

    case HOME_CLIENT_PROJECTS_FAILURE:
      return {
        ...state,
        clientProjectsPending: false,
        clientProjectsError: action.data.error,
      };

    case HOME_CLIENT_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        clientProjectsError: null,
      };

    default:
      return state;
  }
}
