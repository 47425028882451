import {
  HOME_CLONE_PENDING_REQUEST_BEGIN,
  HOME_CLONE_PENDING_REQUEST_SUCCESS,
  HOME_CLONE_PENDING_REQUEST_FAILURE,
  HOME_CLONE_PENDING_REQUEST_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';

export function clonePendingRequest(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CLONE_PENDING_REQUEST_BEGIN,
    });

    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/clone/pending-request`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CLONE_PENDING_REQUEST_SUCCESS,
            data: res,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: HOME_CLONE_PENDING_REQUEST_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>clonePendingRequest', err);
        },
      );
    });

    return promise;
  };
}

export function dismissClonePendingRequestError() {
  return {
    type: HOME_CLONE_PENDING_REQUEST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CLONE_PENDING_REQUEST_BEGIN:
      return {
        ...state,
        clonePendingRequestPending: true,
        clonePendingRequestError: null,
      };

    case HOME_CLONE_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        clonePendingRequestPending: false,
        clonePendingRequestError: null,
      };

    case HOME_CLONE_PENDING_REQUEST_FAILURE:
      return {
        ...state,
        clonePendingRequestPending: false,
        clonePendingRequestError: action.data.error,
      };

    case HOME_CLONE_PENDING_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        clonePendingRequestError: null,
      };

    default:
      return state;
  }
}
