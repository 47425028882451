import {
  HOME_HOSTING_PROJECT_SUBSCRIPTION_BEGIN,
  HOME_HOSTING_PROJECT_SUBSCRIPTION_SUCCESS,
  HOME_HOSTING_PROJECT_SUBSCRIPTION_FAILURE,
  HOME_HOSTING_PROJECT_SUBSCRIPTION_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';

export function hostingProjectSubscription(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_HOSTING_PROJECT_SUBSCRIPTION_BEGIN,
    });

    const { planType, priceId, projectId } = args;
    const config = builderAuthHeader();
    config.headers['Access-Control-Allow-Origin'] = '*';
    const body = { planType, priceId };
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}payment/create-project-session/${projectId}`,
        body,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_HOSTING_PROJECT_SUBSCRIPTION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_HOSTING_PROJECT_SUBSCRIPTION_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>stripePayment', err);
        },
      );
    });

    return promise;
  };
}

export function dismissHostingProjectSubscriptionError() {
  return {
    type: HOME_HOSTING_PROJECT_SUBSCRIPTION_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_HOSTING_PROJECT_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        hostingProjectSubscriptionPending: true,
        hostingProjectSubscriptionError: null,
      };

    case HOME_HOSTING_PROJECT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        hostingProjectSubscriptionPending: false,
        hostingProjectSubscriptionError: null,
      };

    case HOME_HOSTING_PROJECT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        hostingProjectSubscriptionPending: false,
        hostingProjectSubscriptionError: action.data.error,
      };

    case HOME_HOSTING_PROJECT_SUBSCRIPTION_DISMISS_ERROR:
      return {
        ...state,
        hostingProjectSubscriptionError: null,
      };

    default:
      return state;
  }
}
