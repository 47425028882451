import {
  HOME_DELETE_CLIENT_PROJECT_BEGIN,
  HOME_DELETE_CLIENT_PROJECT_SUCCESS,
  HOME_DELETE_CLIENT_PROJECT_FAILURE,
  HOME_DELETE_CLIENT_PROJECT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import Swal from 'sweetalert2';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';

export function deleteClientProject(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_DELETE_CLIENT_PROJECT_BEGIN,
    });
    const { projectId } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.delete(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/${projectId}`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_DELETE_CLIENT_PROJECT_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.data.status === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Project deleted successfully',
            }).then(() => {
              window.location.href = '/dashboard';
            });
          } else if (res.data.status === 'failed') {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.data.message,
            });
          }
        },

        (err) => {
          dispatch({
            type: HOME_DELETE_CLIENT_PROJECT_FAILURE,
            data: { error: err },
          });
          reject(err);

          if (err.response && err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then(() => {
              errorLogOut();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Internal Server Error',
              text: 'Failed to delete project',
            });
          }
        },
      );
    });

    return promise;
  };
}

export function dismissDeleteClientProjectError() {
  return {
    type: HOME_DELETE_CLIENT_PROJECT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_DELETE_CLIENT_PROJECT_BEGIN:
      return {
        ...state,
        deleteClientProjectPending: true,
        deleteClientProjectError: null,
      };

    case HOME_DELETE_CLIENT_PROJECT_SUCCESS:
      return {
        ...state,
        deleteClientProjectPending: false,
        deleteClientProjectError: null,
      };

    case HOME_DELETE_CLIENT_PROJECT_FAILURE:
      return {
        ...state,
        deleteClientProjectPending: false,
        deleteClientProjectError: action.data.error,
      };

    case HOME_DELETE_CLIENT_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        deleteClientProjectError: null,
      };

    default:
      return state;
  }
}
