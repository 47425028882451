import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import * as actions from './redux/actions';
import Layout from './Layout';
import ProjectHostingPlan from './setting/ProjectHostingPlan';

export class ProjectCloudUpgrade extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions, match } = this.props;
    const { params } = match;
    const { projectUuid } = params;
    actions.fetchProject({ projectId: projectUuid });
  }

  handleUpgrade = async (planType, priceId) => {
    const { actions, home } = this.props;
    const { project } = home;

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
    const res = await actions.hostingProjectSubscription({
      priceId,
      planType,
      projectId: project.uuid,
    });
    if (res && res.data && res.data.id) {
      stripe.redirectToCheckout({ sessionId: res.data.id });
    } else {
      console.error('Invalid API response:', res);
    }
  };

  cancelUpgrade = async () => {
    const { actions, home } = this.props;
    const { project } = home;
    const result = await Swal.fire({
      icon: 'warning',
      title: `This will cancel your hosting plan.`,
      showCancelButton: true,
      confirmButtonText: 'Cancel Subscription',
    });

    if (result.value) {
      try {
        actions.cancelUpgrade({ projectUuid: project.uuid });
      } catch (error) {
        console.error('Error cancelling subscription:', error);
      }
    }
  };

  render() {
    const { home, actions } = this.props;
    const { project } = home;
    return (
      <Layout currentPage="Project Hosting">
        <div className="main-content position-relative max-height-vh-100 h-100 mt-5">
          <div className="container-fluid px-2 px-md-4">
            <div className="mx-3 mx-md-4">
              <div className="row">
                <div className="row">
                  <div className="col-12 ">
                    <div className="mb-5 ps-3">
                      <h4 className="mb-3">{project.name}'s Hosting Plan</h4>
                      <hr className="dark horizontal my-3" />
                      <ProjectHostingPlan
                        project={project}
                        handleUpgrade={this.handleUpgrade}
                        cancelUpgrade={this.cancelUpgrade}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCloudUpgrade);
