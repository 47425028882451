import {
  HOME_TEAM_MEMBER_LIST_BEGIN,
  HOME_TEAM_MEMBER_LIST_SUCCESS,
  HOME_TEAM_MEMBER_LIST_FAILURE,
  HOME_TEAM_MEMBER_LIST_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeaderWithParams } from '../../common/utils/auth';
export function teamMemberList(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_TEAM_MEMBER_LIST_BEGIN,
    });
    const { page, size, projectId } = args;
    const params = {
      page,
      size,
    };
    const config = builderAuthHeaderWithParams(params);
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        process.env.REACT_APP_ORGANIZATION_ENGINE_URL + `projects/${projectId}/team-member-list`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_TEAM_MEMBER_LIST_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_TEAM_MEMBER_LIST_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>teamMemberList', err);
        },
      );
    });

    return promise;
  };
}

export function dismissTeamMemberListError() {
  return {
    type: HOME_TEAM_MEMBER_LIST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_TEAM_MEMBER_LIST_BEGIN:
      return {
        ...state,
        teamMemberListPending: true,
        teamMemberListError: null,
      };

    case HOME_TEAM_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        teamMemberListPending: false,
        teamMemberListError: null,
        teamMemberList: action.data,
      };

    case HOME_TEAM_MEMBER_LIST_FAILURE:
      return {
        ...state,
        teamMemberListPending: false,
        teamMemberListError: action.data.error,
      };

    case HOME_TEAM_MEMBER_LIST_DISMISS_ERROR:
      return {
        ...state,
        teamMemberListError: null,
      };

    default:
      return state;
  }
}
