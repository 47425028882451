import cookie from 'react-cookies';
import * as Yup from 'yup';

export const projectPermissions = [
  { label: 'Project not allowed to clone', value: 'CLONE_NOT_ALLOWED' },
  {
    label: 'Project allowed to clone after approval',
    value: 'CLONE_WITH_PERMISSION',
  },
  {
    label: 'Project can be clone without permission',
    value: 'CLONE_WITHOUT_PERMISSION',
  },
];

export const bsFrameworkVersionOptions = [
  { value: 'BOOTSTRAP_4_6', version: '4.6', label: 'Bootstrap 4' },
  { value: 'BOOTSTRAP_5_2', version: '5.2', label: 'Bootstrap 5' },
];

export const OrgPlanOptions = [
  { value: 'BUILDER_FREE', label: 'Free Plan' },
  {
    value: 'BUILDER_1',
    label: 'Professional Plan',
  },
  {
    value: 'BUILDER_2',
    label: 'Growth Plan',
  },
  {
    value: 'BUILDER_3',
    label: 'Growth Plan',
  },
  {
    value: 'BUILDER_4',
    label: 'Enterprise',
  },
  {
    value: 'UNLIMITED',
    label: 'Developer',
  },
];

export const FREE = 'FREE';
export const PAID_1 = 'PAID_1';
export const PAID_2 = 'PAID_2';
export const PAID_3 = 'PAID_3';
export const PAID_4 = 'PAID_4';
export const PAID_5 = 'PAID_5';
export const PAID_6 = 'PAID_6';
export const PAID_7 = 'PAID_7';

export const ProjectPlanOptions = [
  { value: FREE, label: 'No Hosting' },
  {
    value: PAID_1,
    label: 'Starter Plan',
  },
  {
    value: PAID_2,
    label: 'Professional Plan',
  },
  {
    value: PAID_3,
    label: 'Growth Plan',
  },
  {
    value: PAID_4,
    label: 'Enterprise 1',
  },
  {
    value: PAID_5,
    label: 'Enterprise 2',
  },
  {
    value: PAID_6,
    label: 'Enterprise 3',
  },
  {
    value: PAID_7,
    label: 'Enterprise 4',
  },
];

export const getProjectTypeLabel = (value) => {
  const planOption = ProjectPlanOptions.find((option) => option.value === value);
  return planOption ? planOption.label : 'N/A';
};

export const getOrgSubscriptionLabel = (value) => {
  const planOption = OrgPlanOptions.find((option) => option.value === value);
  return planOption ? planOption.label : 'N/A';
};

export const showProjectPlan = (project) => {
  if (!project) {
    return ProjectPlanOptions.find((plan) => plan.value === 'FREE').label;
  }
  const projectPlan = ProjectPlanOptions.find((plan) => plan.value === project.projectType);
  return projectPlan.label;
};

export const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const createTooltipInfo = (project) => {
  const { projectType } = project;
  let description = '';
  console.log('projectType :>> ', projectType);
  if (!projectType) {
    return '';
  }

  if (projectType === FREE) {
    return `Hosting: This project don't have any hosting plan. Only Preview/Test environment will be available.`;
  } else if (projectType === PAID_1) {
    return `Hosting: This project is in Starter Plan. Your application will be hosted to the cloud according to Starter Plan. Please checkout our hosting plan for more details`;
  } else if (projectType === PAID_2) {
    return `Hosting: This project is in Professional Plan. Your application will be hosted to the cloud according to Professional Plan. Please checkout our hosting plan for more details`;
  } else if (projectType === PAID_3) {
    return `Hosting: Please checkout our hosting plan for more details.`;
  }
};

export const prepareProjectMessage = (organization, projectStats) => {
  const { projectCountAllowed, maxFreeProjects, maxPaidProjects, subscription } = organization;
  if (subscription === 'BUILDER_FREE') {
    return '';
  } else if (subscription === 'UNLIMITED') {
    return '';
  }
  const { freeProjects, paidProjects } = projectStats;
  let message = ``;
  if (freeProjects < maxFreeProjects) {
    console.log('Free project available.');
    message = `You can create ${maxFreeProjects - freeProjects} free project/s.`;
    message = `${message} ${preparePaidProjectMessage(
      projectCountAllowed,
      maxPaidProjects,
      paidProjects,
      true,
    )}`;
  } else {
    message = 'Your free project limit reached.';
    console.log('Free project limit reached. Check for paid projects.');
    message = `${message} ${preparePaidProjectMessage(
      projectCountAllowed,
      maxPaidProjects,
      paidProjects,
      false,
    )}`;
  }
  return message;
};

const preparePaidProjectMessage = (
  projectCountAllowed,
  maxPaidProjects,
  paidProjects,
  isFreeAvailable = false,
) => {
  let message = '';
  if (paidProjects < projectCountAllowed) {
    message = `You can mark ${
      projectCountAllowed - paidProjects
    } project/s as a paid (included in your subscription).`;
    return message;
  }
  if (paidProjects < maxPaidProjects) {
    message = `You can have ${maxPaidProjects - paidProjects} more paid project/s.`;
  } else {
    if (isFreeAvailable) {
    } else {
      message = `You have reached projects limit. Please contact us.`;
    }
  }
  return message;
};

export const prepareProjectStats = (organization, projectStats) => {
  const { projectCountAllowed, maxFreeProjects, maxPaidProjects } = organization;
  const { freeProjects, paidProjects } = projectStats;
  if (freeProjects < maxFreeProjects) {
    return { success: true };
  }
  return preparePaidProjectStats(projectCountAllowed, maxPaidProjects, paidProjects, false);
};

const preparePaidProjectStats = (
  projectCountAllowed,
  maxPaidProjects,
  paidProjects,
  isFreeAvailable = false,
) => {
  if (paidProjects < projectCountAllowed) {
    return {
      success: false,
      message: `Free project limit reached. Mark any project paid(included in your subscription)`,
    };
  }

  if (paidProjects < maxPaidProjects) {
    return {
      success: false,
      message: `Free project limit reached. Mark any project paid.`,
    };
  } else {
    return {
      success: false,
      message: `You have reached projects limit. Please contact us.`,
    };
  }
};
