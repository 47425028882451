import { toast } from 'react-toastify';
import {
  HOME_CANCEL_UPGRADE_BEGIN,
  HOME_CANCEL_UPGRADE_SUCCESS,
  HOME_CANCEL_UPGRADE_FAILURE,
  HOME_CANCEL_UPGRADE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';

export function cancelUpgrade(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CANCEL_UPGRADE_BEGIN,
    });

    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}payment/cancel-subscription/${args.projectUuid}`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          const { data } = res;
          if (data.success) {
            dispatch({
              type: HOME_CANCEL_UPGRADE_SUCCESS,
              data: res,
            });
            toast.success(data.message);
            resolve(res);
          } else {
            toast.error(data.message);
          }
        },
        (err) => {
          dispatch({
            type: HOME_CANCEL_UPGRADE_FAILURE,
            data: { error: err },
          });
          if (err.response && err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then(() => {
              errorLogOut();
            });
          } else {
            toast.error('Failed');
          }
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCancelUpgradeError() {
  return {
    type: HOME_CANCEL_UPGRADE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CANCEL_UPGRADE_BEGIN:
      return {
        ...state,
        cancelUpgradePending: true,
        cancelUpgradeError: null,
      };

    case HOME_CANCEL_UPGRADE_SUCCESS:
      return {
        ...state,
        cancelUpgradePending: false,
        cancelUpgradeError: null,
      };

    case HOME_CANCEL_UPGRADE_FAILURE:
      return {
        ...state,
        cancelUpgradePending: false,
        cancelUpgradeError: action.data.error,
      };

    case HOME_CANCEL_UPGRADE_DISMISS_ERROR:
      return {
        ...state,
        cancelUpgradeError: null,
      };

    default:
      return state;
  }
}
