import {
  HOME_INVITE_TEAM_MEMBER_BEGIN,
  HOME_INVITE_TEAM_MEMBER_SUCCESS,
  HOME_INVITE_TEAM_MEMBER_FAILURE,
  HOME_INVITE_TEAM_MEMBER_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';

export function inviteTeamMember(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_INVITE_TEAM_MEMBER_BEGIN,
    });
    const { projectId, email } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        process.env.REACT_APP_ORGANIZATION_ENGINE_URL +
          `clients/invite-team-member/${email}/project/${projectId}`,
        {},
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_INVITE_TEAM_MEMBER_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.data.status === 'success') {
            toast.success('Team Member invited successfully');
          } else {
            toast.error(res.data.message);
          }
        },

        (err) => {
          dispatch({
            type: HOME_INVITE_TEAM_MEMBER_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Internal server error');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissInviteTeamMemberError() {
  return {
    type: HOME_INVITE_TEAM_MEMBER_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_INVITE_TEAM_MEMBER_BEGIN:
      return {
        ...state,
        inviteTeamMemberPending: true,
        inviteTeamMemberError: null,
      };

    case HOME_INVITE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        inviteTeamMemberPending: false,
        inviteTeamMemberError: null,
      };

    case HOME_INVITE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        inviteTeamMemberPending: false,
        inviteTeamMemberError: action.data.error,
      };

    case HOME_INVITE_TEAM_MEMBER_DISMISS_ERROR:
      return {
        ...state,
        inviteTeamMemberError: null,
      };

    default:
      return state;
  }
}
