import initialState from './initialState';
import { reducer as getAllProjectsReducer } from './getAllProjects';
import { reducer as clientDetailsReducer } from './clientDetails';
import { reducer as createClientProjectReducer } from './createClientProject';
import { reducer as fetchProjectReducer } from './fetchProject';
import { reducer as deleteClientProjectReducer } from './deleteClientProject';
import { reducer as updateClientProjectReducer } from './updateClientProject';
import { reducer as changeProjectPermissionReducer } from './changeProjectPermission';
import { reducer as transferOwnershipReducer } from './transferOwnership';
import { reducer as inviteTeamMemberReducer } from './inviteTeamMember';
import { reducer as teamMemberListReducer } from './teamMemberList';
import { reducer as getVersionsReducer } from './getVersions';
import { reducer as teamMemberPendingRequestsReducer } from './teamMemberPendingRequests';
import { reducer as removeTeamMemeberReducer } from './removeTeamMemeber';
import { reducer as projectActivitiesReducer } from './projectActivities';
import { reducer as clientOrganizationReducer } from './clientOrganization';
import { reducer as updateProfileReducer } from './updateProfile';
import { reducer as deleteClientProfileReducer } from './deleteClientProfile';
import { reducer as clientProjectsReducer } from './clientProjects';
import { reducer as getTemplatesReducer } from './getTemplates';
import { reducer as checkCloningReducer } from './checkCloning';
import { reducer as cloneProjectReducer } from './cloneProject';
import { reducer as cloningRequestReducer } from './cloningRequest';
import { reducer as clonePendingRequestReducer } from './clonePendingRequest';
import { reducer as projectCloneStatusReducer } from './projectCloneStatus';
import { reducer as clientPendingRequestReducer } from './clientPendingRequest';
import { reducer as stripePaymentReducer } from './stripePayment';
import { reducer as changeTeamMemberRequestReducer } from './changeTeamMemberRequest';
import { reducer as loadPendingInviteReducer } from './loadPendingInvite';
import { reducer as changeRequestStatusReducer } from './changeRequestStatus';
import { reducer as loadMemberOrgsReducer } from './loadMemberOrgs';
import { reducer as loadOrgMembersReducer } from './loadOrgMembers';
import { reducer as fetchInvoiceReducer } from './fetchInvoice';
import { reducer as fetchLineItemsReducer } from './fetchLineItems';
import { reducer as verifyPaymentStatusReducer } from './verifyPaymentStatus';
import { reducer as changeProjectStatusReducer } from './changeProjectStatus';
import { reducer as hostingProjectSubscriptionReducer } from './hostingProjectSubscription';
import { reducer as cancelUpgradeReducer } from './cancelUpgrade';
import { reducer as loadProjectStatsReducer } from './loadProjectStats';

const reducers = [
  getAllProjectsReducer,
  clientDetailsReducer,
  createClientProjectReducer,
  fetchProjectReducer,
  deleteClientProjectReducer,
  updateClientProjectReducer,
  changeProjectPermissionReducer,
  transferOwnershipReducer,
  inviteTeamMemberReducer,
  teamMemberListReducer,
  getVersionsReducer,
  teamMemberPendingRequestsReducer,
  removeTeamMemeberReducer,
  projectActivitiesReducer,
  clientOrganizationReducer,
  updateProfileReducer,
  deleteClientProfileReducer,
  clientProjectsReducer,
  getTemplatesReducer,
  checkCloningReducer,
  cloneProjectReducer,
  cloningRequestReducer,
  clonePendingRequestReducer,
  projectCloneStatusReducer,
  clientPendingRequestReducer,
  stripePaymentReducer,
  changeTeamMemberRequestReducer,
  loadPendingInviteReducer,
  changeRequestStatusReducer,
  loadMemberOrgsReducer,
  loadOrgMembersReducer,
  fetchInvoiceReducer,
  fetchLineItemsReducer,
  verifyPaymentStatusReducer,
  changeProjectStatusReducer,
  hostingProjectSubscriptionReducer,
  cancelUpgradeReducer,
  loadProjectStatsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
