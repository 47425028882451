import cookie from 'react-cookies';
import { LOGIN_LOGIN_SUCCESS } from '../../features/login/redux/constants';
function checkAuthorization(dispatch) {
  const storedToken = cookie.load('token');
  if (storedToken) {
    const uuid = cookie.load('uuid');
    const token = cookie.load('token');
    const username = cookie.load('username');
    const role = cookie.load('role');
    const name = cookie.load('name');
    const country = cookie.load('country');
    dispatch({
      type: LOGIN_LOGIN_SUCCESS,
      token,
      role,
      uuid,
      name,
      country,
      username,
    });
    return { access: true, role: role };
  }
  return { access: false, role: '' };
}

export function checkWidgetAuthorization({ dispatch, getState }, ...rest) {
  const token = getState().login.token;
  if (token) {
    return { access: true, role: getState().login.role };
  }

  let status = checkAuthorization(dispatch);
  if (rest.length > 0) {
    return { access: status.access && rest[0].path.includes('/'), role: status.role };
  }
  return status;
}
