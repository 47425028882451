import cookie from 'react-cookies';

export const builderAuthHeader = () => {
  const token = cookie.load('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
  return config;
};

export const builderAuthHeaderWithParams = (params) => {
  const token = cookie.load('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    params,
  };
  return config;
};

export const builderAuthHeaderWithNoContentType = () => {
  const token = cookie.load('token');
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return config;
};

export const errorLogOut = () => {
  cookie.remove('token', { path: '/' });
  cookie.remove('name', { path: '/' });
  cookie.remove('role', { path: '/' });
  cookie.remove('uuid', { path: '/' });
  cookie.remove('username', { path: '/' });
  window.location.href = '/';
};
