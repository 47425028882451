export const orgPriceDetails = {
  test: {
    BUILDER_1: 'price_1QMqsuC8MSObRTqDzilu2KQy',
    BUILDER_2: 'price_1QMqtPC8MSObRTqDoD8VAWNQ',
  },
  live: {
    BUILDER_1: 'price_1QMtHtAxNvKBf8ANPWpIAi8E',
    BUILDER_2: 'price_1QMtKdAxNvKBf8ANDS4fuCA7',
  },
};

export const projectPriceDetails = {
  test: {
    PAID_1: 'price_1QO02XC8MSObRTqDllAJSbIl',
    PAID_2: 'price_1QO02vC8MSObRTqDFRym015L',
  },
  live: {
    PAID_1: 'price_1QMtRiAxNvKBf8ANTY1eh1In',
    PAID_2: 'price_1QMtRiAxNvKBf8ANTY1eh1In',
  },
};
