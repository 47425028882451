import React from 'react';

const CustomPagination = ({ currentPage, totalPages, handlePageChange }) => {
  console.log('currentPage :>> ', currentPage);
  return (
    <nav className="mt-4" aria-label="Page navigation example">
      {totalPages > 1 && (
        <ul className="pagination d-flex justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i
                className="material-icons text-2xl position-relative"
                style={{ fontSize: '37px', marginBottom: '17px' }}
              >
                &#171;
              </i>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <button
                className={`page-link ${currentPage === page ? '#171347 text-white' : ''}`}
                style={{
                  backgroundColor: `${currentPage === page ? '#171347' : ''}`,
                  border: '0.5px solid #fff',
                }}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button
              className="page-link "
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <i
                className="material-icons text-2xl position-relative"
                style={{ fontSize: '37px', marginBottom: '17px' }}
              >
                &#187;
              </i>
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default CustomPagination;
