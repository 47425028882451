import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactUsModal from './ContactUsModal';



export class Calculator extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    categories: [],
    features: [],
    selectedCategory: null,
    addedFeatures: {},
    estimateFeatureId: null,
    estimatedFeatures: [],
    totalCost: 0,
    totalDays: 0,
    count: 0,
  };
  componentDidMount() {
    const { actions } = this.props;
    actions.getCategories();
    actions.getFeatures();
    actions.createEstimateFeature();


    this.newAddedFeature = null;
  }
  handleCategoryClick = (e, uuid) => {
    e.preventDefault();
    this.setState({ selectedCategory: uuid });
  };
  handleAddFeature = async (feature, featureName) => {
    const { addedFeatures } = this.state;
    const { actions } = this.props;

    // console.log("handle add feature estimate feature", this.props);
    const { login } = this.props;
    // console.log("here", login);
    const { createEstimateFeature } = login
    const { uuid: estimateFeatureId } = createEstimateFeature
    // console.log(featureName);
    if (!estimateFeatureId) {
      console.error('Estimate feature ID is not available');
      return;
    }

    const body = {
      estimateFeatureId,
      featureId: feature.uuid,
    };

    const isFeatureAdded = addedFeatures[featureName];

    try {
      if (!isFeatureAdded) {
        // POST action called 
        const addResponse = await actions.addFeature({ body });
        // console.log("...add.....", addResponse);

        //GET action call (LineItems)
        const estimateFeatureResponses = await actions.getLineItems({ estimateFeatureId });
        // console.log("getlineitems....",estimateFeatureResponses );

        const { login } = this.props;
        // console.log("here", login);
        const { estimateFeatureData } = login;
        // console.log("here", estimateFeatureData);
        const lineitemuuid = estimateFeatureData[0].uuid;
        // console.log('Fetched estimate feature uuid:', lineitemuuid);

        this.setState((prevState) => {
          const updatedAddedFeatures = {
            ...prevState.addedFeatures,
            [featureName]: lineitemuuid,
          };

          // console.log('Updated addedFeatures object:', updatedAddedFeatures);

          return {
            addedFeatures: updatedAddedFeatures,
          };
        }, () => {
          // const {actions} = this.props;  
          // actions.getEstimatedFeatures({estimateFeatureId});

          //refresh
          this.fetchEstimatedFeatures();
        });
        toast.success(`The feature ${featureName} has been added!`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error updating feature:', error);
      toast.error("There was an error adding the feature.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  handleRemoveFeature = async (lineItemUuid) => {
    try {
      const { login, actions } = this.props;
      // console.log("here", login);
      const { createEstimateFeature, getEstimatedFeatures } = login;
      const { uuid: estimateFeatureId } = createEstimateFeature;

      if (!estimateFeatureId) {
        console.error('Estimate feature ID is not available');
        return;
      }

      const featureToRemove = getEstimatedFeatures.find(feature => feature.uuid === lineItemUuid);

      if (!featureToRemove) {
        console.error('Feature not found');
        return;
      }

      // Get the name of the feature to remove
      const featureNameToRemove = featureToRemove.name;
      // console.log(`Removing feature: ${featureNameToRemove}`);

      // delete call for lineItem
      const removeResponses = await actions.removeLineItem({ lineItemUuid, estimateFeatureId });


      // console.log('Feature removed successfully');

      this.setState((prevState) => {
        //  addedFeatures object without the removed feature
        const updatedAddedFeatures = { ...prevState.addedFeatures };
        delete updatedAddedFeatures[featureNameToRemove]; // Remove feature by its name

        // Filter out the removed feature from estimatedFeatures
        const updatedEstimatedFeatures = prevState.estimatedFeatures.filter(
          (feature) => feature.uuid !== lineItemUuid
        );

        const totalCost = updatedEstimatedFeatures.reduce((acc, feature) => acc + feature.cost, 0);
        const totalDays = updatedEstimatedFeatures.reduce((acc, feature) => acc + feature.duration, 0);

        return {
          estimatedFeatures: updatedEstimatedFeatures,
          addedFeatures: updatedAddedFeatures,
          totalCost,
          totalDays,
        };
      }, this.fetchEstimatedFeatures);
      toast.success(`The feature ${featureNameToRemove} has been removed!`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error removing feature:', error);
      toast.error("There was an error removing the feature.", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  fetchEstimatedFeatures = async () => {


    const { login } = this.props;
    const { createEstimateFeature } = login;
    const { uuid: estimateFeatureId } = createEstimateFeature;
    this.setState({ estimateFeatureId });;

    if (!estimateFeatureId) return;

    const { actions } = this.props;
    await actions.getEstimatedFeatures({ estimateFeatureId });
    const { getEstimatedFeatures } = login;
    if (getEstimatedFeatures.length) {
      // console.log("Fetched updated features:", getEstimatedFeatures);
    }
  };
  categoryClose = () => {
    this.setState({ showCategory: false });
  };

  categoryShow = () => {
    this.setState({ showCategory: true });
  };
  modalClose = () => {
    this.setState({ showModal: false });
  }
  modalShow = () => {
    this.setState({ showModal: true });
  }
  handleSubmit = (values) => {
    console.log('Form submitted:', values);
    this.modalClose();
  };

  render() {
    const { selectedCategory, addedFeatures, showModal } = this.state;
    const { login } = this.props;
    const { categories, features, getEstimatedFeatures } = login;
    // console.log("res here is this.props calculator render", this.props);
    const totalCost = getEstimatedFeatures && getEstimatedFeatures.reduce((acc, feature) => acc + feature.cost, 0);
    const totalDays = getEstimatedFeatures && getEstimatedFeatures.reduce((acc, feature) => acc + feature.duration, 0);

    const filteredFeatures = selectedCategory
      ? features.filter(feature => feature.category.uuid === selectedCategory)
      : features;

    return (
      <div>
        {/* navbar area  */}
        <Navbar expand="lg" sticky='top' >
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <div>
                  <h3 onClick={this.categoryShow} style={{ cursor: 'pointer' }}>
                    <img
                      src="/icons/menu.svg"
                      style={{ height: '30px', width: '30px' }}
                      alt="Icon"
                      className="my-auto mx-2 mb-1"
                    />
                    Category
                  </h3>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* area for categories  */}
        <Offcanvas className="w-20" show={this.state.showCategory} onHide={this.categoryClose} >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className='mx-3'><h4>Select Categories</h4></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul
              className="list-unstyled"
              style={{
                fontSize: "18px",
                height: "580px",
                overflow: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {categories.map((category) => (
                <li key={category.uuid} className="mb-2 mt-2 rounded ">
                  <a
                    href="#"
                    className="p-3"
                    onClick={(e) => this.handleCategoryClick(e, category.uuid)}
                  >

                    {category.name}
                  </a>
                </li>
              ))}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <ContactUsModal
          showModal={showModal}
          handleClose={this.modalClose}
          totalCost={totalCost}
          handleSubmit={this.handleSubmit}
          estimateFeatureId={this.state.estimateFeatureId}

        />


        <div className='section container-fluid' style={{ backgroundColor: '#FAF9F6' }}>
          <div className="container">
            <div className="row">

              {/* features section */}
              <div className="col-md-7" >
                <div className="d-flex justify-content-between align-items-center mt-5">
                  <h2>Select features to add</h2>
                  <input type="text" className="form-control w-25 border px-2 bg-white" placeholder="Search..." />
                </div>
                <div className=" px-3">
                  <div className="row mt-5" style={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
                    {filteredFeatures.map((feature) => (
                      <div key={feature.uuid} className="col-md-4 mb-4">
                        <div className="card shadow-none border" style={{ backgroundColor: 'white', border: 'none', borderRadius: '15px', Height: '400px' }}>
                          <div className="card-body">
                            <h5 className="card-title " style={{ width: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '18px' }}>{feature.name}</h5>
                            <p className="card-text" style={{ height: '80px', fontSize: '13px', scrollbarWidth: 'thin' }}>{feature.description}</p>
                            <hr />
                            <div className="row">
                              <div className="col-md-6">
                                <h2 className="text-start" style={{ fontSize: '20px', marginBottom: '0' }}>${feature.cost}</h2>
                                <p className="text-muted  mx-auto" style={{ fontSize: '13px' }}><b>Costs</b></p>
                              </div>
                              <div className="col-md-6">
                                <h2 className="text-end" style={{ fontSize: '20px', marginBottom: '0' }}>{feature.duration} Days</h2>
                                <p className="text-muted text-end" style={{ fontSize: '13px' }}><b>Duration</b></p>
                              </div>
                            </div>
                            {/* buttons  */}
                            <a
                              href="#"
                              className="btn d-flex justify-content-center w-100"
                              style={{
                                backgroundColor: addedFeatures[feature.name] ? '#BF0000' : '#1AA6AD',
                                color: 'white',
                                borderRadius: '25px',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (addedFeatures[feature.name]) {

                                  const estimatedFeature = getEstimatedFeatures.find(f => f.name === feature.name);

                                  if (estimatedFeature) {
                                    this.handleRemoveFeature(estimatedFeature.uuid);
                                  }
                                } else {
                                  this.handleAddFeature(feature, feature.name);
                                }
                              }}
                            >
                              {addedFeatures[feature.name] ? 'Remove' : 'Add'}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </div>

              {/* line item section  */}
              <div className="col-md-5 mt-5">
                <div className="container-fluid rounded pt-2 mb-2 bg-white  border d-flex flex-column justify-content-between" style={{ height: '600px', position: 'sticky', top: '70px' }}  >

                  <div className="row">
                    <div className="table-responsive " style={{ overflowY: 'scroll', height: '60vh', scrollbarWidth: 'none' }}
                    >
                      <table className="table border rounded-3 text-dark" >
                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                          <tr>
                            <th>Features</th>
                            <th>Days</th>
                            <th>Cost</th>
                            <th></th>
                          </tr>
                          <tr>
                          </tr>
                        </thead>
                        <tbody>
                          {getEstimatedFeatures.map((feature, index) => (
                            <tr key={feature.uuid}>
                              <td style={{ paddingLeft: '1.5rem' }}>{feature.name}</td>
                              <td style={{ paddingLeft: '1.5rem' }}>{feature.duration}</td>
                              <td style={{ paddingLeft: '1.5rem' }}>${feature.cost}</td>
                              <td>
                                <a>
                                  <img
                                    src="/icons/delete.png"
                                    style={{ height: "25px", width: "25px", cursor: 'pointer' }}
                                    alt="Icon "
                                    className="me-3 hover-overlay"
                                    onClick={() => this.handleRemoveFeature(feature.uuid)}
                                  />
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6 mt-3 text-start ">
                      <h5 className="mx-1">Tentative Time: {totalDays} Days </h5>
                    </div>
                    <div className="col-md-6 mt-3 text-end">
                      <h5>Approx Price: ${totalCost}</h5>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className='btn w-95' onClick={this.modalShow} style={{ fontSize: '16px', backgroundColor: '#1AA6AD', color: 'white' }}>get a quote</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state.login,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calculator);