import axios from 'axios';
import {
  HOME_CHANGE_TEAM_MEMBER_REQUEST_BEGIN,
  HOME_CHANGE_TEAM_MEMBER_REQUEST_SUCCESS,
  HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE,
  HOME_CHANGE_TEAM_MEMBER_REQUEST_DISMISS_ERROR,
} from './constants';

export function changeTeamMemberRequest(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CHANGE_TEAM_MEMBER_REQUEST_BEGIN,
    });
    const { requestId, status } = args;
    if (!requestId) {
      dispatch({
        type: HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE,
        data: { error: `Invalid Request ID` },
      });
    }
    if (!status || status !== 'approve' || status !== 'reject') {
      dispatch({
        type: HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE,
        data: { error: `Invalid Request Type` },
      });
    }
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/team-member/${requestId}/change-invitation-status/${status}`,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CHANGE_TEAM_MEMBER_REQUEST_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissChangeTeamMemberRequestError() {
  return {
    type: HOME_CHANGE_TEAM_MEMBER_REQUEST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CHANGE_TEAM_MEMBER_REQUEST_BEGIN:
      return {
        ...state,
        changeTeamMemberRequestPending: true,
        changeTeamMemberRequestError: null,
      };

    case HOME_CHANGE_TEAM_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        changeTeamMemberRequestPending: false,
        changeTeamMemberRequestError: null,
        requestStatus: action.data.message,
      };

    case HOME_CHANGE_TEAM_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        changeTeamMemberRequestPending: false,
        changeTeamMemberRequestError: action.data.error,
      };

    case HOME_CHANGE_TEAM_MEMBER_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        changeTeamMemberRequestError: null,
      };

    default:
      return state;
  }
}
