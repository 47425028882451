import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipInfo = ({ description, name }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  console.log('description :>> ', description);
  return (
    <>
      <i className="material-icons text-primary text-gradient" id={name}>
        info
      </i>
      <Tooltip autohide={true} isOpen={tooltipOpen} target={name} toggle={toggle}>
        {description || ''}
      </Tooltip>
    </>
  );
};

export default TooltipInfo;
