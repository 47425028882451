import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import ClientPricingCards from './ClientPricingCards';
import Layout from './Layout';
export class ClientAccounts extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Layout currentPage="Accounts">
        <div className="main-content position-relative max-height-vh-100 h-100 mt-5">
          <div className="container-fluid px-2 px-md-4">
            <div className="mx-3 mx-md-4">
              <div className="row">
                <div className="row">
                  <div className="col-12 ">
                    <div className="mb-5 ps-3">
                      <h4 className="mb-3">Change Your Plan</h4>
                      <hr className="dark horizontal my-3" />
                    </div>
                    <ClientPricingCards />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAccounts);
