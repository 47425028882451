import axios from 'axios';
import {
  HOME_VERIFY_PAYMENT_STATUS_BEGIN,
  HOME_VERIFY_PAYMENT_STATUS_SUCCESS,
  HOME_VERIFY_PAYMENT_STATUS_FAILURE,
  HOME_VERIFY_PAYMENT_STATUS_DISMISS_ERROR,
} from './constants';
import { builderAuthHeader } from '../../common/utils/auth';

export function verifyPaymentStatus(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_VERIFY_PAYMENT_STATUS_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}payment/verify-session`,
        args,
        config,
      );
      doRequest.then(
        (res) => {
          const { data } = res;
          if (data.success) {
            dispatch({
              type: HOME_VERIFY_PAYMENT_STATUS_SUCCESS,
              data: data.message,
            });
            resolve(data.message);
          } else {
            dispatch({
              type: HOME_VERIFY_PAYMENT_STATUS_FAILURE,
              data: { error: data.message },
            });
            reject(data.message);
          }
        },
        (err) => {
          dispatch({
            type: HOME_VERIFY_PAYMENT_STATUS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissVerifyPaymentStatusError() {
  return {
    type: HOME_VERIFY_PAYMENT_STATUS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_VERIFY_PAYMENT_STATUS_BEGIN:
      return {
        ...state,
        verifyPaymentStatusPending: true,
        verifyPaymentStatusError: null,
      };

    case HOME_VERIFY_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        verifyPaymentStatusPending: false,
        verifyPaymentStatusError: null,
        afterPaymentResponse: action.data,
      };

    case HOME_VERIFY_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        verifyPaymentStatusPending: false,
        verifyPaymentStatusError: action.data.error,
        afterPaymentResponse: '',
      };

    case HOME_VERIFY_PAYMENT_STATUS_DISMISS_ERROR:
      return {
        ...state,
        verifyPaymentStatusError: null,
      };

    default:
      return state;
  }
}
