import React from 'react';
import cookie from 'react-cookies';
import InviteDetail from './InviteDetail';

const Invites = ({ pendingInvites, changeInviteStatus }) => {
  if (!pendingInvites || !pendingInvites.length) return '';
  const username = cookie.load('username');
  const sentInvite = [];
  const receivedInvite = [];
  pendingInvites.forEach((invite) => {
    if (invite.senderUsername === username) {
      sentInvite.push(invite);
    } else {
      receivedInvite.push(invite);
    }
  });

  return (
    <>
      <InviteDetail invites={receivedInvite} sent={false} changeInviteStatus={changeInviteStatus} />
      <InviteDetail invites={sentInvite} sent={true} changeInviteStatus={changeInviteStatus} />
    </>
  );
};

export default Invites;
