import {
  HOME_DELETE_CLIENT_PROFILE_BEGIN,
  HOME_DELETE_CLIENT_PROFILE_SUCCESS,
  HOME_DELETE_CLIENT_PROFILE_FAILURE,
  HOME_DELETE_CLIENT_PROFILE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';

export function deleteClientProfile(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_DELETE_CLIENT_PROFILE_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.delete(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/email/admin`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_DELETE_CLIENT_PROFILE_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.status === 200) {
            toast.success(`Thank You for using ${process.env.REACT_APP_PROJECT_NAME}`);
            setTimeout(() => {
              errorLogOut();
            }, 1000);
          }
        },
        (err) => {
          dispatch({
            type: HOME_DELETE_CLIENT_PROFILE_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Oops! Something went wrong');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissDeleteClientProfileError() {
  return {
    type: HOME_DELETE_CLIENT_PROFILE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_DELETE_CLIENT_PROFILE_BEGIN:
      return {
        ...state,
        deleteClientProfilePending: true,
        deleteClientProfileError: null,
      };

    case HOME_DELETE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        deleteClientProfilePending: false,
        deleteClientProfileError: null,
      };

    case HOME_DELETE_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        deleteClientProfilePending: false,
        deleteClientProfileError: action.data.error,
      };

    case HOME_DELETE_CLIENT_PROFILE_DISMISS_ERROR:
      return {
        ...state,
        deleteClientProfileError: null,
      };

    default:
      return state;
  }
}
