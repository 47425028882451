import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link } from 'react-router-dom';
import drapcodePng from '../../assets/img/DrapCode.png';
import CustomPagination from '../../common/CustomPagination';
import Layout from './Layout';
import voca from 'voca';
import parse from 'html-react-parser';
// import * as parse from 'html-react-parser';

export class TemplateSelect extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentPage: 1,
    perPage: 6,
  };

  async componentDidMount() {
    document.title = 'Dashboard - Drapcode';
    const { actions } = this.props;
    const { currentPage, perPage } = this.state;
    actions.clientProjects({ page: currentPage - 1, size: perPage });
    actions.getTemplates({ page: currentPage - 1, size: perPage });
  }
  handlePageChange = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;

    const newPage = Math.max(
      1,
      Math.min(page, Math.ceil(this.props.home.clientProjects.totalPages)),
    );

    this.setState({ currentPage: newPage }, () => {
      actions.clientProjects({ page: newPage - 1, size: perPage });
    });
  };
  handlePageChangeTemp = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;

    const newPage = Math.max(
      1,
      Math.min(page, Math.ceil(this.props.home.templatesData.totalPages)),
    );

    this.setState({ currentPage: newPage }, () => {
      actions.getTemplates({ page: newPage - 1, size: perPage });
    });
  };
  renderCard(item) {
    return (
      <div className="col-md-6 mb-4" key={item.id}>
        <div className="card p-3 h-100">
          <div className="row g-0">
            <div className="col-4 d-flex align-items-center">
              <img
                src={drapcodePng}
                className="img-fluid "
                alt={`Image for ${item.name}`}
                style={{ borderRadius: '5px' }}
              />
            </div>
            <div className="col-8">
              <div className="card-body" style={{ minHeight: '180px' }}>
                <div>
                  <h5 className="card-title" title={item.name}>
                    {voca.truncate(voca.titleCase(item.name), 25)}
                  </h5>
                  <p className="text-dark" title={item.description || 'No description available.'}>
                    {parse(voca.truncate(item.description || 'No description available.', 90))}
                  </p>
                </div>
              </div>
              <div>
                <Link
                  to={`/${item.uuid}/clone`}
                  className="btn btn-success btn-sm text-white flex-fill float-end m-0"
                >
                  <i className="material-icons text-lg position-relative">content_copy</i> Clone
                  Project
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTemplates(template) {
    const { REACT_APP_S3_UPLOAD_URL } = this.props;
    const descriptionContent =
      template.metaDescription ||
      template.shortDescription ||
      template.description ||
      'No description available.';
    const titleDes =
      template.metaDescription || template.shortDescription || 'No description available.';
    return (
      <div className="col-md-6 mb-4" key={template.id}>
        <div className="card p-3 h-100">
          <div className="row g-0">
            <div className="col-4 d-flex align-items-center">
              <img
                src={REACT_APP_S3_UPLOAD_URL + template.images[0]}
                className="img-fluid "
                alt={`Image for ${template.title}`}
                style={{ borderRadius: '5px' }}
              />
            </div>
            <div className="col-8">
              <div className="card-body" style={{ minHeight: '180px' }}>
                <div>
                  <h5 className="card-title" title={template.title}>
                    <a href={template.livePreviewUrl} target="_blank">
                      {voca.truncate(voca.titleCase(template.title), 25)}{' '}
                    </a>
                  </h5>
                  <p className="text-dark" title={titleDes}>
                    {parse(voca.truncate(descriptionContent, 90))}
                  </p>
                </div>
              </div>
              <div>
                <a
                  href={template.projectCloneUrl}
                  target="_blank"
                  className="btn btn-success btn-sm text-white flex-fill float-end m-0"
                >
                  <i className="material-icons text-lg position-relative">content_copy</i> Clone
                  Template
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoDataMessage(section) {
    return (
      <div className="text-center">
        <h3>No {section} available to clone!</h3>
      </div>
    );
  }

  render() {
    const { home } = this.props;
    const { clientProjects, templatesData } = home;
    const { content, totalPages } = clientProjects ? clientProjects : {};
    const { currentPage } = this.state;
    return (
      <Layout currentPage="Select Project Templates">
        <div className=" mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-5 mt-2 m-3">
                <p className="text-dark" style={{ fontSize: 'large' }}>
                  {
                    'You can quickly create a new project by selecting from various ready-made templates or by cloning an existing project. When you choose the cloning option, the new project will automatically include all the pages, collections, and events from the original project. This saves you time and effort, providing a fully functional starting point for customization.'
                  }
                </p>
              </div>
              <div className="container">
                <div className="row">
                  {content && content.length > 0
                    ? content && content.map((project) => this.renderCard(project))
                    : this.renderNoDataMessage('projects')}
                </div>
              </div>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={this.handlePageChange}
              />
              <hr className="dark horizontal my-5" />
              <h2 className="mb-4 mt-5">Templates</h2>
              <div className="container mt-6 mb-9">
                <div className="row">
                  {templatesData.content && templatesData.content.length > 0
                    ? templatesData.content &&
                      templatesData.content.map((template) => this.renderTemplates(template))
                    : this.renderNoDataMessage('templates')}
                </div>
              </div>
              <CustomPagination
                currentPage={currentPage}
                totalPages={templatesData.totalPages}
                handlePageChange={this.handlePageChangeTemp}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    REACT_APP_S3_UPLOAD_URL: process.env.REACT_APP_S3_UPLOAD_URL,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelect);
