import {
  Dashboard,
  CreateProject,
  CreateProjectForm,
  OpenBuilder,
  ProjectDetail,
  Setting,
  ClientProfile,
  ClientAccounts,
  TemplateSelect,
  CloneProject,
  OpenBuilderCwp,
  OpenBuilderCp,
  ClientDetail,
  TeamMemberReject,
  Team,
  Bill,
  AfterCheckout,
  ProjectCloudUpgrade,
} from './';

export default {
  path: '',
  childRoutes: [
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, isPrivate: true },
    {
      path: '/create-project-select',
      name: 'Create project',
      component: CreateProject,
      isPrivate: true,
    },
    {
      path: '/create-project',
      name: 'Create project form',
      component: CreateProjectForm,
      isPrivate: true,
    },
    {
      path: '/:projectUuid/open-builder',
      name: 'Open builder',
      component: OpenBuilder,
      isPrivate: true,
    },
    {
      path: '/:projectUuid/project-detail',
      name: 'Project detail',
      component: ProjectDetail,
      isPrivate: true,
    },
    {
      path: '/profile',
      name: 'Client profile',
      component: ClientProfile,
      isPrivate: true,
    },
    {
      path: '/accounts',
      name: 'Client accounts',
      component: ClientAccounts,
      isPrivate: true,
    },
    {
      path: '/template-select',
      name: 'Template select',
      component: TemplateSelect,
      isPrivate: true,
    },
    {
      path: '/:projectUuid/clone',
      name: 'Clone project',
      component: CloneProject,
      isPrivate: true,
    },
    {
      path: '/:projectUuid/open-builder-clone-without-permission',
      name: 'Open builder cwp',
      component: OpenBuilderCwp,
      isPrivate: true,
    },
    {
      path: '/open-builder-clone-with-permission',
      name: 'Open builder cp',
      component: OpenBuilderCp,
      isPrivate: true,
    },
    {
      path: '/:clientId/detail',
      name: 'Client detail',
      component: ClientDetail,
      isPrivate: true,
    },
    {
      path: '/team-member/:requestId/:status',
      name: 'Team member reject',
      component: TeamMemberReject,
      isPrivate: true,
    },
    { path: '/teams', name: 'Team', component: Team, isPrivate: true },
    { path: '/bills', name: 'Bill', component: Bill, isPrivate: true },
    { path: '/after-checkout', name: 'After checkout', component: AfterCheckout, isPrivate: true },
    {
      path: '/:projectUuid/cloud-hosting',
      name: 'Project cloud upgrade',
      component: ProjectCloudUpgrade,
      isPrivate: true,
    },
  ],
};
