import {
  HOME_PROJECT_ACTIVITIES_BEGIN,
  HOME_PROJECT_ACTIVITIES_SUCCESS,
  HOME_PROJECT_ACTIVITIES_FAILURE,
  HOME_PROJECT_ACTIVITIES_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';
export function projectActivities(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_PROJECT_ACTIVITIES_BEGIN,
    });
    const { projectId } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        process.env.REACT_APP_ORGANIZATION_ENGINE_URL + `projectActivities/${projectId}`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_PROJECT_ACTIVITIES_SUCCESS,
            data: res,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: HOME_PROJECT_ACTIVITIES_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>projectActivities', err);
        },
      );
    });

    return promise;
  };
}

export function dismissProjectActivitiesError() {
  return {
    type: HOME_PROJECT_ACTIVITIES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_PROJECT_ACTIVITIES_BEGIN:
      return {
        ...state,
        projectActivitiesPending: true,
        projectActivitiesError: null,
      };

    case HOME_PROJECT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        projectActivitiesPending: false,
        projectActivitiesError: null,
      };

    case HOME_PROJECT_ACTIVITIES_FAILURE:
      return {
        ...state,
        projectActivitiesPending: false,
        projectActivitiesError: action.data.error,
      };

    case HOME_PROJECT_ACTIVITIES_DISMISS_ERROR:
      return {
        ...state,
        projectActivitiesError: null,
      };

    default:
      return state;
  }
}
