import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';

export class TeamMemberReject extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { match, actions } = this.props;
    const { requestId, status } = match.params;
    actions.changeTeamMemberRequest({ requestId, status });
  }

  render() {
    const { changeTeamMemberRequestPending, requestStatus } = this.props.home;
    //TODO: Show Loading
    return (
      <main className="main-content mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <a href="https://drapcode.com/" target="_blank">
                <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
              </a>
              <div className="row">
                <div className="col-md-12">
                  <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                    <h4 className="text-center">
                      {requestStatus || 'You have rejected the invitation request !'}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberReject);
