import {
  HOME_LOAD_PROJECT_STATS_BEGIN,
  HOME_LOAD_PROJECT_STATS_SUCCESS,
  HOME_LOAD_PROJECT_STATS_FAILURE,
  HOME_LOAD_PROJECT_STATS_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';

export function loadProjectStats(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_LOAD_PROJECT_STATS_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/stats/project`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_LOAD_PROJECT_STATS_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_LOAD_PROJECT_STATS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissLoadProjectStatsError() {
  return {
    type: HOME_LOAD_PROJECT_STATS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_LOAD_PROJECT_STATS_BEGIN:
      return {
        ...state,
        loadProjectStatsPending: true,
        loadProjectStatsError: null,
      };

    case HOME_LOAD_PROJECT_STATS_SUCCESS:
      return {
        ...state,
        loadProjectStatsPending: false,
        loadProjectStatsError: null,
        projectStats: action.data,
      };

    case HOME_LOAD_PROJECT_STATS_FAILURE:
      return {
        ...state,
        loadProjectStatsPending: false,
        loadProjectStatsError: action.data.error,
      };

    case HOME_LOAD_PROJECT_STATS_DISMISS_ERROR:
      return {
        ...state,
        loadProjectStatsError: null,
      };

    default:
      return state;
  }
}
