import React from 'react';

const OrganizationDetail = ({ organization, clientDetail }) => {
  const { owner, projects } = organization;
  const { firstName, lastName } = owner;
  const fProjects = projects.filter((project) =>
    project.members.find((member) => member.username === clientDetail.username),
  );

  return (
    <tr>
      <td className="align-middle text-center">
        {firstName} {lastName}
      </td>
      <td className="align-middle text-center">
        {fProjects &&
          fProjects.map((project) => <span className="badge badge-free-plan">{project.name}</span>)}
      </td>
    </tr>
  );
};

export default OrganizationDetail;
