import React, { Component } from 'react';
import Select from 'react-select';

export default class CustomSelect extends Component {
  handleChange = (values) => {
    let value;
    const { isObject } = this.props;
    if (values instanceof Array || this.props.isMulti) {
      value = values
        ? values.map((val) => {
            return val.value;
          })
        : [];

      if (this.props.onChangeValue) {
        this.props.onChangeValue([this.props.name], value);
      }
    } else {
      if (isObject === true) {
        value = values || '';
      } else {
        value = values ? values.value : '';
      }
      if (this.props.onChangeValue) {
        this.props.onChangeValue([this.props.name], value);
      }
    }
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    this.props.onBlur([this.props.name], true);
  };

  render() {
    const { name, options, value, placeholder, isMulti, isDisabled } = this.props;
    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: '#fff' }),
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontSize: '0.875rem',
          color: isDisabled ? null : isSelected ? 'white' : isFocused ? 'white' : null,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? '#1ba7ae'
            : isFocused
            ? '#1ba7ae9c'
            : null,

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? '#fff' : '#1ba7ae'),
          },
        };
      },
    };

    let valuesList = null;
    if (isMulti) {
      valuesList = value ? options.filter((option) => value.indexOf(option.value) >= 0) : [];
    } else {
      valuesList = options ? options.find((option) => option && option.value === value) : [];
    }
    return (
      <Select
        name={name}
        isMulti={isMulti}
        className="custom-select-container"
        classNamePrefix="custom-select"
        options={options}
        value={valuesList}
        placeholder={placeholder}
        onChange={this.handleChange}
        styles={{ ...colourStyles, menu: (styles) => ({ ...styles, zIndex: 999 }) }}
        isClearable={true}
        isDisabled={isDisabled ? true : false}
        filterOption={(candidate, input) => {
          return (
            candidate.data.__isNew__ ||
            candidate.data.label.toLowerCase().includes(input ? input.toLowerCase() : '')
          );
        }}
        getOptionLabel={(e) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {e.icon && <i className={e.icon} style={{ lineHeight: '0' }}></i>}
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
      />
    );
  }
}
