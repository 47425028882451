import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as actions from './redux/actions';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';
import CustomLoader from '../common/CustomLoader';
import { resetPasswordValidationSchema } from '../common/utils/form-validation';
import DashboardFooter from '../home/Footer';
export class ResetPassword extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.querySelector('body').classList.remove('bg-gray-200');
  }

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const { newPassword } = values;
      const { actions, match } = this.props;
      const { params } = match;
      const { id } = params;
      const data = {
        uuid: id,
        password: newPassword,
      };
      const res = await actions.resetPassword({ data });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { login } = this.props;
    const { resetPasswordPending } = login;
    return (
      <main className="main-content mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <div className="row">
                <a href="https://drapcode.com/" target="_blank">
                  <div className="col-md-12">
                    <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                  </div>
                </a>
                <div className="col-md-12">
                  <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="font-weight-bolder">Reset Password</h4>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{ newPassword: '', confirmNewPassword: '' }}
                          validationSchema={resetPasswordValidationSchema}
                          onSubmit={this.handleSubmit}
                        >
                          {({ touched, errors, isSubmitting }) => (
                            <Form>
                              <div className="input-group input-group-outline mb-3">
                                <Field
                                  type="password"
                                  name="newPassword"
                                  placeholder="New Password"
                                  className={`form-control ${
                                    touched.newPassword && errors.newPassword ? 'is-invalid' : ''
                                  }`}
                                />
                                {touched.newPassword && errors.newPassword && (
                                  <div className="invalid-feedback">{errors.newPassword}</div>
                                )}
                              </div>
                              <div className="input-group input-group-outline mb-3">
                                <Field
                                  type="password"
                                  name="confirmNewPassword"
                                  placeholder="Confirm New Password"
                                  className={`form-control ${
                                    touched.confirmNewPassword && errors.confirmNewPassword
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                />
                                {touched.confirmNewPassword && errors.confirmNewPassword && (
                                  <div className="invalid-feedback">
                                    {errors.confirmNewPassword}
                                  </div>
                                )}
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary mt-3 w-100"
                                  disabled={isSubmitting || resetPasswordPending}
                                >
                                  {' '}
                                  Submit
                                  <CustomLoader loading={isSubmitting || resetPasswordPending} />
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fixed-bottom">
          <DashboardFooter />
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
