import React from 'react';
import voca from 'voca';
import { formatDate } from '../../common/utils/date-utils';

const LineItemDetail = ({ item, index }) => {
  let { amount, description, lineItemType, status, dateCreated } = item;
  let textColor = '';
  if (status === 'NEW') {
    textColor = 'primary';
  } else if (status === 'NOT_PAID') {
    textColor = 'danger';
  } else if (status === 'PAID') {
    textColor = 'success';
  }
  description = voca.replace(description, 'BUILDER_1', 'Professional');
  description = voca.replace(description, 'BUILDER_2', 'Growth');
  description = voca.replace(description, 'PAID_1', 'Starter');
  description = voca.replace(description, 'PAID_2', 'Professional');

  return (
    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
      <div className="d-flex align-items-center">
        <div
          className={`btn btn-icon-only btn-rounded btn-outline-${textColor} mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center`}
          style={{ width: '4.5rem' }}
        >
          {status}
        </div>
        <div className="d-flex flex-column">
          <h6 className={`mb-1 text-${textColor} text-sm`}>{description}</h6>
          <span className="text-xs">{formatDate(dateCreated)}</span>
        </div>
      </div>
      <div
        className={`d-flex align-items-center text-${textColor} text-gradient text-sm font-weight-bold`}
      >
        ${amount}
      </div>
    </li>
  );
};

export default LineItemDetail;
