export const LOGIN_LOGIN_BEGIN = 'LOGIN_LOGIN_BEGIN';
export const LOGIN_LOGIN_SUCCESS = 'LOGIN_LOGIN_SUCCESS';
export const LOGIN_LOGIN_FAILURE = 'LOGIN_LOGIN_FAILURE';
export const LOGIN_LOGOUT_REQUEST = 'LOGIN_LOGOUT_REQUEST';
export const LOGIN_LOGIN_DISMISS_ERROR = 'LOGIN_LOGIN_DISMISS_ERROR';
export const LOGIN_SIGN_UP_BEGIN = 'LOGIN_SIGN_UP_BEGIN';
export const LOGIN_SIGN_UP_SUCCESS = 'LOGIN_SIGN_UP_SUCCESS';
export const LOGIN_SIGN_UP_FAILURE = 'LOGIN_SIGN_UP_FAILURE';
export const LOGIN_SIGN_UP_DISMISS_ERROR = 'LOGIN_SIGN_UP_DISMISS_ERROR';
export const LOGIN_FORGOT_PASSWORD_BEGIN = 'LOGIN_FORGOT_PASSWORD_BEGIN';
export const LOGIN_FORGOT_PASSWORD_SUCCESS = 'LOGIN_FORGOT_PASSWORD_SUCCESS';
export const LOGIN_FORGOT_PASSWORD_FAILURE = 'LOGIN_FORGOT_PASSWORD_FAILURE';
export const LOGIN_FORGOT_PASSWORD_DISMISS_ERROR = 'LOGIN_FORGOT_PASSWORD_DISMISS_ERROR';
export const LOGIN_RESET_PASSWORD_BEGIN = 'LOGIN_RESET_PASSWORD_BEGIN';
export const LOGIN_RESET_PASSWORD_SUCCESS = 'LOGIN_RESET_PASSWORD_SUCCESS';
export const LOGIN_RESET_PASSWORD_FAILURE = 'LOGIN_RESET_PASSWORD_FAILURE';
export const LOGIN_RESET_PASSWORD_DISMISS_ERROR = 'LOGIN_RESET_PASSWORD_DISMISS_ERROR';
export const LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN = 'LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN';
export const LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS = 'LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS';
export const LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE = 'LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE';
export const LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR = 'LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR';
export const LOGIN_GET_CATEGORIES_BEGIN = 'LOGIN_GET_CATEGORIES_BEGIN';
export const LOGIN_GET_CATEGORIES_SUCCESS = 'LOGIN_GET_CATEGORIES_SUCCESS';
export const LOGIN_GET_CATEGORIES_FAILURE = 'LOGIN_GET_CATEGORIES_FAILURE';
export const LOGIN_GET_CATEGORIES_DISMISS_ERROR = 'LOGIN_GET_CATEGORIES_DISMISS_ERROR';
export const LOGIN_GET_FEATURES_BEGIN = 'LOGIN_GET_FEATURES_BEGIN';
export const LOGIN_GET_FEATURES_SUCCESS = 'LOGIN_GET_FEATURES_SUCCESS';
export const LOGIN_GET_FEATURES_FAILURE = 'LOGIN_GET_FEATURES_FAILURE';
export const LOGIN_GET_FEATURES_DISMISS_ERROR = 'LOGIN_GET_FEATURES_DISMISS_ERROR';
export const LOGIN_CREATE_ESTIMATE_FEATURE_BEGIN = 'LOGIN_CREATE_ESTIMATE_FEATURE_BEGIN';
export const LOGIN_CREATE_ESTIMATE_FEATURE_SUCCESS = 'LOGIN_CREATE_ESTIMATE_FEATURE_SUCCESS';
export const LOGIN_CREATE_ESTIMATE_FEATURE_FAILURE = 'LOGIN_CREATE_ESTIMATE_FEATURE_FAILURE';
export const LOGIN_CREATE_ESTIMATE_FEATURE_DISMISS_ERROR = 'LOGIN_CREATE_ESTIMATE_FEATURE_DISMISS_ERROR';
export const LOGIN_GET_ESTIMATED_FEATURES_BEGIN = 'LOGIN_GET_ESTIMATED_FEATURES_BEGIN';
export const LOGIN_GET_ESTIMATED_FEATURES_SUCCESS = 'LOGIN_GET_ESTIMATED_FEATURES_SUCCESS';
export const LOGIN_GET_ESTIMATED_FEATURES_FAILURE = 'LOGIN_GET_ESTIMATED_FEATURES_FAILURE';
export const LOGIN_GET_ESTIMATED_FEATURES_DISMISS_ERROR = 'LOGIN_GET_ESTIMATED_FEATURES_DISMISS_ERROR';
export const LOGIN_ADD_FEATURE_BEGIN = 'LOGIN_ADD_FEATURE_BEGIN';
export const LOGIN_ADD_FEATURE_SUCCESS = 'LOGIN_ADD_FEATURE_SUCCESS';
export const LOGIN_ADD_FEATURE_FAILURE = 'LOGIN_ADD_FEATURE_FAILURE';
export const LOGIN_ADD_FEATURE_DISMISS_ERROR = 'LOGIN_ADD_FEATURE_DISMISS_ERROR';
export const LOGIN_GET_LINE_ITEMS_BEGIN = 'LOGIN_GET_LINE_ITEMS_BEGIN';
export const LOGIN_GET_LINE_ITEMS_SUCCESS = 'LOGIN_GET_LINE_ITEMS_SUCCESS';
export const LOGIN_GET_LINE_ITEMS_FAILURE = 'LOGIN_GET_LINE_ITEMS_FAILURE';
export const LOGIN_GET_LINE_ITEMS_DISMISS_ERROR = 'LOGIN_GET_LINE_ITEMS_DISMISS_ERROR';
export const LOGIN_REMOVE_LINE_ITEM_BEGIN = 'LOGIN_REMOVE_LINE_ITEM_BEGIN';
export const LOGIN_REMOVE_LINE_ITEM_SUCCESS = 'LOGIN_REMOVE_LINE_ITEM_SUCCESS';
export const LOGIN_REMOVE_LINE_ITEM_FAILURE = 'LOGIN_REMOVE_LINE_ITEM_FAILURE';
export const LOGIN_REMOVE_LINE_ITEM_DISMISS_ERROR = 'LOGIN_REMOVE_LINE_ITEM_DISMISS_ERROR';
