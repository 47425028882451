import {
  LOGIN_FORGOT_PASSWORD_BEGIN,
  LOGIN_FORGOT_PASSWORD_SUCCESS,
  LOGIN_FORGOT_PASSWORD_FAILURE,
  LOGIN_FORGOT_PASSWORD_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import Swal from 'sweetalert2';

export function forgotPassword(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_FORGOT_PASSWORD_BEGIN,
    });
    const { data } = args;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/forgot-password`,
        data,
        config,
      );
      doRequest.then(
        (res) => {
          if (res.status === 200) {
          } else if (res.status === 404) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'User not found.',
            });
          }
          dispatch({
            type: LOGIN_FORGOT_PASSWORD_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: err.response.data,
            });
          } else if (err.response.status === 404) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'User not found.',
            });
          }
          dispatch({
            type: LOGIN_FORGOT_PASSWORD_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissForgotPasswordError() {
  return {
    type: LOGIN_FORGOT_PASSWORD_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        forgotPasswordPending: true,
        forgotPasswordError: null,
      };

    case LOGIN_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: null,
      };

    case LOGIN_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: action.data.error,
      };

    case LOGIN_FORGOT_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        forgotPasswordError: null,
      };

    default:
      return state;
  }
}
