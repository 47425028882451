import React from 'react';

const DashboardFooter = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="position-relative mt-auto">
      <footer className="footer py-4">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="copyright text-center text-sm text-muted text-lg-start">
                DrapCode © {getCurrentYear()}. All Rights Reserved.
              </div>
            </div>
            <div className="col-lg-6">
              <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                <li className="nav-item">
                  <a
                    href="https://drapcode.com/legal/terms-of-use"
                    className="nav-link text-muted"
                    target="_blank"
                  >
                    Terms Of Use
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://drapcode.com/legal/privacy-policy"
                    className="nav-link text-muted"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://drapcode.com/legal/cookie-policy"
                    className="nav-link text-muted"
                    target="_blank"
                  >
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DashboardFooter;
