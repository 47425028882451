import {
  HOME_UPDATE_PROFILE_BEGIN,
  HOME_UPDATE_PROFILE_SUCCESS,
  HOME_UPDATE_PROFILE_FAILURE,
  HOME_UPDATE_PROFILE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { builderAuthHeader } from '../../common/utils/auth';
export function updateProfile(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_UPDATE_PROFILE_BEGIN,
    });
    const { data } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.put(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/update/client`,
        data,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_UPDATE_PROFILE_SUCCESS,
            data: res.data,
          });
          resolve(res);
          if (res.status === 200) {
            toast.success('Profile updated successfully');
          }
        },
        (err) => {
          dispatch({
            type: HOME_UPDATE_PROFILE_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 400) {
            toast.error(err.response.data);
          }
        },
      );
    });

    return promise;
  };
}
export function dismissUpdateProfileError() {
  return {
    type: HOME_UPDATE_PROFILE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_UPDATE_PROFILE_BEGIN:
      return {
        ...state,
        updateProfilePending: true,
        updateProfileError: null,
      };

    case HOME_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfilePending: false,
        updateProfileError: null,
        clientDetail: action.data,
      };

    case HOME_UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfilePending: false,
        updateProfileError: action.data.error,
      };

    case HOME_UPDATE_PROFILE_DISMISS_ERROR:
      return {
        ...state,
        updateProfileError: null,
      };

    default:
      return state;
  }
}
