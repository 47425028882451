import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link } from 'react-router-dom';
import drapcodePng from '../../assets/img/DrapCode.png';
import Layout from './Layout';

export class CreateProject extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    document.title = 'Create Project - Drapcode';
  }

  render() {
    return (
      <Layout currentPage="Create Project">
        <div className="container-fluid  d-flex flex-column mt-4">
          <div className="tm-section-title custom-h1-tm-section-title">
            <h3>Create Project</h3>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="mb-2 mt-2 m-3">
              <p className="text-dark" style={{ fontSize: 'large' }}>
                {
                  "Welcome to DrapCode! We're excited to have you on board. Let's get started by setting up your project. You can either create a blank project from scratch or clone an existing project from a template or your previous projects."
                }
              </p>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-12">
              <section className="top-bottom-140-padding">
                <div className="container-fluid">
                  <div className="justify-content-center mb-3">
                    <div className="mb-3">
                      <div className="card">
                        <div className="card-body  flex-column ">
                          <div className="d-flex justify-content-between ">
                            <div>
                              <h5 className="card-title">Create a blank project from scratch</h5>

                              <p className="m-0 text-dark">
                                Start a fresh new project with a blank canvas.
                              </p>
                            </div>
                            <div className="align-items-end d-flex">
                              <Link
                                to="/create-project"
                                className="btn btn-success btn-md text-white m-0"
                              >
                                <i className="material-icons text-lg position-relative">
                                  arrow_forward
                                </i>{' '}
                                Start
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="card">
                        <div className="card-body  flex-column ">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="card-title">
                                {' '}
                                Clone a project from a template or an existing project
                              </h5>

                              <p className="m-0 text-dark">
                                Quickly get started by cloning a ready-made template or one of your
                                previous projects.
                              </p>
                            </div>
                            <div className="align-items-end d-flex">
                              <Link
                                to="/template-select"
                                className="btn btn-success btn-md text-white m-0"
                              >
                                <i className="material-icons text-lg position-relative">
                                  arrow_forward
                                </i>{' '}
                                Start
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center mb-3">
                    <div className="mb-3">
                      <div className="card" style={{ opacity: 0.6, pointerEvents: 'none' }}>
                        <div className="card-body  flex-column ">
                          <div className="d-flex justify-content-between ">
                            <div>
                              <h5 className="card-title">
                                {`Build Apps using command prompt (coming soon...)`}
                              </h5>
                              <p className="m-0 text-dark">
                                Coming soon! A powerful command-line tool for developers to build.
                              </p>
                            </div>
                            <div className="align-items-end d-flex">
                              <Link to="#" className="btn btn-success btn-md text-white m-0">
                                <i className="material-icons text-lg position-relative">
                                  arrow_forward
                                </i>{' '}
                                Start
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card d-none"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
