import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './history';
import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import loginReducer from '../features/login/redux/reducer';

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  login: loginReducer,
};

export default combineReducers(reducerMap);
