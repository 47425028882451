const initialState = {
  token: '',
  role: '',
  uuid: '',
  username: '',
  name: '',
  country: '',
  categories:[],
  features:[],
  createEstimateFeature:{},
  getEstimatedFeatures:[],
  getLineItems:[],
  estimateFeatureData:[],
  isLoggedIn: false,
  loginPending: false,
  loginError: null,
  signUpPending: false,
  signUpError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  confirmClientRegisterPending: false,
  confirmClientRegisterError: null,
  getCategoriesPending: false,
  getCategoriesError: null,
  getFeaturesPending: false,
  getFeaturesError: null,
  createEstimateFeaturePending: false,
  createEstimateFeatureError: null,
  getEstimatedFeaturesPending: false,
  getEstimatedFeaturesError: null,
  addFeaturePending: false,
  addFeatureError: null,
  getLineItemsPending: false,
  getLineItemsError: null,
  removeLineItemPending: false,
  removeLineItemError: null,
};

export default initialState;
