import {
  LOGIN_SIGN_UP_BEGIN,
  LOGIN_SIGN_UP_SUCCESS,
  LOGIN_SIGN_UP_FAILURE,
  LOGIN_SIGN_UP_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';

export function signUp(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SIGN_UP_BEGIN,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/`,
        args.data,
        options,
      );
      doRequest.then(
        (res) => {
          const fieldErrors = res.data.fieldErrors;

          if (fieldErrors && fieldErrors.length > 0) {
            const first = [...fieldErrors].shift();

            if (first.code === 'Username is already registered') {
              toast.error('Email is already registered');
            }
          } else if (res.status === 201) {
            toast.success('Sign up successfully.');
          }
          dispatch({
            type: LOGIN_SIGN_UP_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: LOGIN_SIGN_UP_FAILURE,
            data: { error: err },
          });
          if (err.response.status === 400) {
            toast.error('Please check Email and Password');
          }
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSignUpError() {
  return {
    type: LOGIN_SIGN_UP_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_SIGN_UP_BEGIN:
      return {
        ...state,
        signUpPending: true,
        signUpError: null,
      };

    case LOGIN_SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpPending: false,
        signUpError: null,
      };

    case LOGIN_SIGN_UP_FAILURE:
      return {
        ...state,
        signUpPending: false,
        signUpError: action.data.error,
      };

    case LOGIN_SIGN_UP_DISMISS_ERROR:
      return {
        ...state,
        signUpError: null,
      };

    default:
      return state;
  }
}
