import React from 'react';
import voca from 'voca';
import { formatDateWtTime, formatDateUnixWtTime } from '../../common/utils/date-utils';

const InvoiceDetail = ({ invoice }) => {
  const { dueDate, invoiceNumber, status, balance, invoicePdf, hostedInvoiceUrl } = invoice;
  let textColor = 'text-danger';
  if (status === 'PAID') {
    textColor = 'text-success';
  }
  return (
    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
      <div className="d-flex justify-content-between ps-0 mb-2">
        <div className="d-flex flex-column">
          <h6 className={`mb-1 font-weight-bold text-sm ${textColor}`}>
            {formatDateUnixWtTime(dueDate)}
          </h6>
          <span className={`text-xs ${textColor}`}>{invoiceNumber}</span>
        </div>
        <div className="d-flex align-items-center ms-5">
          <h6 className={`font-weight-bold text-sm mx-3 ${textColor}`}>{voca.titleCase(status)}</h6>
          <h6 className={`font-weight-bold text-sm mx-3 ${textColor}`}>{balance}</h6>
        </div>
      </div>

      <div className="d-flex align-items-center">
        {status == 'NEW' && (
          <a
            href={hostedInvoiceUrl}
            target="blank"
            className="btn btn-success btn-sm text-capitalize mb-0"
          >
            <i className="material-icons material-symbols-rounded text-lg position-relative me-1">
              payment
            </i>
            Pay
          </a>
        )}
        <a
          href={invoicePdf}
          className={`btn btn-link text-sm mb-0 px-0 ms-4 ${textColor} text-capitalize`}
        >
          <i className="material-icons material-symbols-rounded text-lg position-relative me-1">
            download
          </i>
          Invoice
        </a>
        &nbsp;&nbsp;
      </div>
    </li>
  );
};

export default InvoiceDetail;
