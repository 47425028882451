import React from 'react';
import CustomPagination from '../../../common/CustomPagination';
import InvoiceDetail from './InvoiceDetail';

const Invoices = ({ invoices, currentInvoicePage, totalPages, handleInvoicePageChange }) => {
  return (
    <div className="card h-100">
      <div className="card-header pb-0 p-3">
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <h6 className="mb-0">Invoices</h6>
          </div>
        </div>
      </div>
      <div className="card-body p-3 pb-0">
        <ul className="list-group">
          {invoices.map((invoice, index) => (
            <InvoiceDetail invoice={invoice} key={invoice.uuid} />
          ))}
        </ul>
        <CustomPagination
          currentPage={currentInvoicePage}
          totalPages={totalPages}
          handlePageChange={handleInvoicePageChange}
        />
      </div>
    </div>
  );
};

export default Invoices;
