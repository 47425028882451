import axios from 'axios';
import {
  LOGIN_CREATE_ESTIMATE_FEATURE_BEGIN,
  LOGIN_CREATE_ESTIMATE_FEATURE_SUCCESS,
  LOGIN_CREATE_ESTIMATE_FEATURE_FAILURE,
  LOGIN_CREATE_ESTIMATE_FEATURE_DISMISS_ERROR,
} from './constants';


export function createEstimateFeature(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_CREATE_ESTIMATE_FEATURE_BEGIN,
    });
    // const token = process.env.REACT_APP_TOKEN;
    const options = {
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };



    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}estimate-features/`, null,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: LOGIN_CREATE_ESTIMATE_FEATURE_SUCCESS,
            //change
            data: res.data,
          });
          resolve(res);
          // console.log("estimate feature id action", res);
        },
        (err) => {
          dispatch({
            type: LOGIN_CREATE_ESTIMATE_FEATURE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateEstimateFeatureError() {
  return {
    type: LOGIN_CREATE_ESTIMATE_FEATURE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_CREATE_ESTIMATE_FEATURE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createEstimateFeaturePending: true,
        createEstimateFeatureError: null,
      };

    case LOGIN_CREATE_ESTIMATE_FEATURE_SUCCESS:
      // The request is success
      return {
        ...state,
        createEstimateFeaturePending: false,
        createEstimateFeatureError: null,
        //change
        createEstimateFeature: action.data,
      };

    case LOGIN_CREATE_ESTIMATE_FEATURE_FAILURE:
      // The request is failed
      return {
        ...state,
        createEstimateFeaturePending: false,
        createEstimateFeatureError: action.data.error,
      };

    case LOGIN_CREATE_ESTIMATE_FEATURE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createEstimateFeatureError: null,
      };

    default:
      return state;
  }
}
