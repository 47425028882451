import React, { Component } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import SyncLoader from 'react-spinners/SyncLoader';
export default class CustomLoader extends Component {
  render() {
    const { loading, css, size, color, margin, sync } = this.props;
    const showLoader = loading;

    let loaderCSS = {
      display: 'inline-block',
    };

    let loaderSize = '6px';
    let loaderMargin = '2px';
    let loaderColor = '#ffff';

    if (css) {
      loaderCSS = css;
    }
    if (size) {
      loaderSize = size;
    }
    if (color) {
      loaderColor = color;
    }
    if (margin) {
      loaderMargin = margin;
    }

    if (sync) {
      return <SyncLoader css={loaderCSS} size={loaderSize} loading={showLoader}></SyncLoader>;
    }
    return (
      <BeatLoader
        css={loaderCSS}
        size={loaderSize}
        margin={loaderMargin}
        color={loaderColor}
        loading={showLoader}
      />
    );
  }
}
