import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import CustomLoader from '../common/CustomLoader';
export class ConfirmClientRegister extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    message: false,
    check: true,
  };
  componentDidMount() {
    const { actions, match, history } = this.props;
    const { params } = match;
    const { id } = params;
    const response = actions.confirmClientRegister({ id }).then((res) => {
      if (response.status === 200 && response.data.message === 'failed') {
        this.setState({ message: true, check: false });
      } else if (response.status === 200 && response.data.message === 'success') {
        history.push('/accounts');
      } else {
        history.push('/accounts');
      }
    });
  }

  render() {
    const { message, check } = this.state;
    return (
      <main className="register-page">
        <section className="section">
          <div className="container-fluid" style={{ margin: '5rem 0 5rem 0' }}>
            <div className="text-center">
              <CustomLoader loading={check} size="20px" color="#303453" />
            </div>
            {message && (
              <div className="row md-m-25px-b m-40px-b justify-content-center">
                <div className="col-lg-7 text-center">
                  <h3 className="h1 m-15px-b p-15px-b after-50 theme-after">OOPS!</h3>
                </div>
              </div>
            )}
            {message && (
              <div className="col-12">
                <h3 style={{ textAlign: 'center' }}>Something went wrong :(</h3>
              </div>
            )}
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmClientRegister);
