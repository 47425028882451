import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';
import {
  HOME_LOAD_MEMBER_ORGS_BEGIN,
  HOME_LOAD_MEMBER_ORGS_SUCCESS,
  HOME_LOAD_MEMBER_ORGS_FAILURE,
  HOME_LOAD_MEMBER_ORGS_DISMISS_ERROR,
} from './constants';

export function loadMemberOrgs(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_LOAD_MEMBER_ORGS_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/members/list`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_LOAD_MEMBER_ORGS_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_LOAD_MEMBER_ORGS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissLoadMemberOrgsError() {
  return {
    type: HOME_LOAD_MEMBER_ORGS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_LOAD_MEMBER_ORGS_BEGIN:
      return {
        ...state,
        loadMemberOrgsPending: true,
        loadMemberOrgsError: null,
      };

    case HOME_LOAD_MEMBER_ORGS_SUCCESS:
      return {
        ...state,
        loadMemberOrgsPending: false,
        loadMemberOrgsError: null,
        memberOrgs: action.data,
      };

    case HOME_LOAD_MEMBER_ORGS_FAILURE:
      return {
        ...state,
        loadMemberOrgsPending: false,
        loadMemberOrgsError: action.data.error,
      };

    case HOME_LOAD_MEMBER_ORGS_DISMISS_ERROR:
      return {
        ...state,
        loadMemberOrgsError: null,
      };

    default:
      return state;
  }
}
