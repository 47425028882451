import axios from 'axios';
import {
  LOGIN_GET_CATEGORIES_BEGIN,
  LOGIN_GET_CATEGORIES_SUCCESS,
  LOGIN_GET_CATEGORIES_FAILURE,
  LOGIN_GET_CATEGORIES_DISMISS_ERROR,
} from './constants';


export function getCategories(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: LOGIN_GET_CATEGORIES_BEGIN,
    });
    //change
    // const token = process.env.REACT_APP_TOKEN;
    const options = {
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const promise = new Promise((resolve, reject) => {
      //change
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}feature-categories/all/`,
        options,
      ); 
      doRequest.then(
        (res) => {
          dispatch({
            type: LOGIN_GET_CATEGORIES_SUCCESS,
            data: res.data,
          });
          resolve(res);
          // console.log("checking categories response", res);
        },
        (err) => {
          dispatch({
            type: LOGIN_GET_CATEGORIES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

// Async action saves request error by default, this method is used to dismiss the error info.
// If you don't want errors to be saved in Redux store, just ignore this method.
export function dismissGetCategoriesError() {
  return {
    type: LOGIN_GET_CATEGORIES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_GET_CATEGORIES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getCategoriesPending: true,
        getCategoriesError: null,
      };

    case LOGIN_GET_CATEGORIES_SUCCESS:
      // The request is success
      return {
        ...state,
        getCategoriesPending: false,
        getCategoriesError: null,
        //change
        categories:action.data,
      };

    case LOGIN_GET_CATEGORIES_FAILURE:
      // The request is failed
      return {
        ...state,
        getCategoriesPending: false,
        getCategoriesError: action.data.error,
      };

    case LOGIN_GET_CATEGORIES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getCategoriesError: null,
      };

    default:
      return state;
  }
}
