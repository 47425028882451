import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { Link } from 'react-router-dom';
import * as actions from './redux/actions';
import SocialMediaLinks from './SocialMediaLinks';
import ResourceLinks from './ResourceLinks';
import CustomPagination from '../../common/CustomPagination';
import VideoModal from './VideoModal';
import Layout from './Layout';
import {
  prepareProjectMessage,
  prepareProjectStats,
  ProjectPlanOptions,
} from '../common/constants';
import ProjectDetailOld from './project/ProjectDetailOld';
const css = {
  margin: 'auto',
  marginTop: 'revert',
  display: 'block',
};
export class Dashboard extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentPage: 1,
    perPage: 12,
    showVideoModal: false,
  };

  componentDidMount() {
    document.title = 'Dashboard - Drapcode';
    const { actions } = this.props;
    const { currentPage, perPage } = this.state;
    actions.getAllProjects({ page: currentPage - 1, size: perPage });
  }

  handlePageChange = (page) => {
    const { actions, home } = this.props;
    const { perPage } = this.state;
    const { projectMetadata } = home;
    const newPage = Math.max(1, Math.min(page, Math.ceil(projectMetadata.totalProjects / perPage)));
    this.setState({ currentPage: newPage }, () => {
      actions.getAllProjects({ page: newPage - 1, size: perPage });
    });
  };

  openVideoModal = () => {
    this.setState({ showVideoModal: true });
  };

  closeVideoModal = () => {
    this.setState({ showVideoModal: false });
  };

  renderProjects() {
    const { login, home, protocol, builderSurface, exchange_surface } = this.props;
    const token = login.token;
    const { orgClient, projectMetadata } = home;
    const { content } = projectMetadata.filteredProjects || {};
    if (!content) {
      return null;
    }
    return (
      <>
        {content.map((project, index) => (
          <div className="col-lg-4 col-md-6 mt-4 mb-4" key={index}>
            <ProjectDetailOld
              project={project}
              protocol={protocol}
              builderSurface={builderSurface}
              exchange_surface={exchange_surface}
              token={token}
            />
          </div>
        ))}
        {/* <table className="table table-bordered align-items-center mb-0 table-hover table-striped">
          <thead>
            <tr>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Name
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Created On
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Framework
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Hosting Plan
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Status
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Members
              </th>
              <th className="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {content.map((project, index) => (
              <ProjectDetail
                key={project.uuid}
                project={project}
                token={token}
                protocol={protocol}
                builderSurface={builderSurface}
                exchange_surface={exchange_surface}
              />
            ))}
          </tbody>
        </table> */}
      </>
    );
  }
  calculateProjectStepPer = (project) => {
    if (!project) {
      return 0;
    }
    const { steps } = project;
    if (!steps || steps.length === 0) {
      return 0;
    }
    const perComplete = steps.map((step) => step.value).reduce((acc, step) => acc + step);
    return perComplete;
  };

  renderCreateProjectLink = () => {
    const { home } = this.props;
    const { orgClient, projectStats } = home;
    const { projectCountAllowed, projects, subscription, maxFreeProjects, maxPaidProjects } =
      orgClient;
    const { freeProjects, paidProjects } = projectStats;
    if (subscription === 'BUILDER_FREE') {
      return (
        <>
          <h6 className="align-center">To start your journey, you have to upgrade your account.</h6>
          <Link to="/accounts" className="btn btn-success btn-md text-white m-0">
            Upgrade your account
          </Link>
        </>
      );
    } else if (subscription === 'UNLIMITED') {
      return (
        <Link className="btn btn-success text-white btn-md" to={'/create-project-select'}>
          <i className="fa fa-plus me-sm-1"></i>
          Create Project
        </Link>
      );
    } else {
      if (!projects || projects.length <= 0) {
        return (
          <Link className="btn btn-success text-white btn-md" to={'/create-project-select'}>
            <i className="fa fa-plus me-sm-1"></i>
            Create Project
          </Link>
        );
      }

      const stats = prepareProjectStats(orgClient, projectStats);
      console.log('stats :>> ', stats);
      if (!stats.success) {
        return <p>{stats.message}</p>;
      }
      return (
        <Link className="btn btn-success text-white btn-md" to={'/create-project-select'}>
          <i className="fa fa-plus me-sm-1"></i>
          Create Project
        </Link>
      );
    }
  };

  zeroProjectUI = () => {
    const { home } = this.props;
    const { projectMetadata, getAllProjectsPending } = home;
    console.log('zeroProjectUI :>> ');
    if (getAllProjectsPending) {
      console.log('1');
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <BeatLoader
                css={{ display: 'inline-block' }}
                size="15px"
                margin="5px"
                color="#344767"
                loading={getAllProjectsPending}
              />
            </div>
          </div>
        </div>
      );
    } else {
      console.log('2');
      const { totalProjects } = projectMetadata ? projectMetadata : { totalProjects: 0 };
      if (totalProjects !== 0) {
        console.log('3');
        console.log(`I have ${totalProjects} project/s so return`);
        return '';
      }
      console.log('4');
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <h3>Welcome!</h3>
              <p className="text-dark" style={{ fontSize: 'large' }}>
                We are excited to commence your DrapCode journey! Get ready
                <br /> to give your cool ideas a concrete shape by building amazing
                <br /> web apps.
              </p>
              {/* don't remove this comment */}
              {/* <button className="btn btn-primary p-2" onClick={this.openVideoModal}>
              <img
                src="https://app.drapcode.com/public/maxresdefault%20(1).jpg"
                style={{ width: '480px' }}
                alt="Short Intro Video"
              />
            </button> */}
              <div className="mb-0 me-3 mt-5">{this.renderCreateProjectLink()}</div>
            </div>
          </div>
          {/* don't remove this cmt */}
          {/* <ResourceLinks protocol={protocol} projectDomain={project_domain} /> */}
          {/* <SocialMediaLinks />{' '} */}
        </div>
      );
    }
  };

  nonZeroProjectUI = () => {
    const { home } = this.props;
    const { projectMetadata, orgClient, projectStats } = home;
    const { totalProjects, filteredProjects } = projectMetadata
      ? projectMetadata
      : { totalProjects: 0 };
    console.log('5');

    if (totalProjects === 0) {
      console.log('6');
      console.log(`I have zero project so return`);
      return '';
    }
    const { totalPages } = filteredProjects ? filteredProjects : 0;
    const { currentPage, perPage, showVideoModal } = this.state;

    const message = prepareProjectMessage(orgClient, projectStats);
    return (
      <>
        <div className="d-flex justify-content-between  mb-0 me-3 mt-5 align-items-center">
          <strong>{message}</strong>
          {this.renderCreateProjectLink()}
        </div>
        <div className="row mt-4">{this.renderProjects()}</div>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={this.handlePageChange}
        />
        <VideoModal isOpen={showVideoModal} toggle={this.closeVideoModal} />
      </>
    );
  };

  render() {
    console.log('Dashboard Render');
    const { home } = this.props;
    const { clientDetail } = home;
    if (!clientDetail) {
      return;
    }

    const { status } = clientDetail;
    console.log('status :>> ', status);
    if (status === 'AWAITING_APPROVAL') {
      return (
        <div className="row mt-4">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
              <h3>Welcome!</h3>
              <p className="text-dark" style={{ fontSize: 'large' }}>
                Your account is not approved. Please contact Admin.
              </p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Layout currentPage="Dashboard">
        {this.zeroProjectUI()}
        {this.nonZeroProjectUI()}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderSurface: process.env.REACT_APP_BUILDER_SURFACE,
    configurationSurface: process.env.REACT_APP_CONFIGURATION_SURFACE,
    exchange_surface: process.env.REACT_APP_EXCHANGE_SURFACE,
    project_domain: process.env.REACT_APP_PROJECT_DOMAIN,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
