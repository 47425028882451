import {
  HOME_CLONE_PROJECT_BEGIN,
  HOME_CLONE_PROJECT_SUCCESS,
  HOME_CLONE_PROJECT_FAILURE,
  HOME_CLONE_PROJECT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { builderAuthHeaderWithNoContentType, errorLogOut } from '../../common/utils/auth';
export function cloneProject(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CLONE_PROJECT_BEGIN,
    });
    const { formData, projectId } = args;
    const config = builderAuthHeaderWithNoContentType();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/${projectId}/generate-clone-request`,
        formData,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CLONE_PROJECT_SUCCESS,
            data: res,
          });
          resolve(res);
          const { status, message, project } = res.data;
          if (status === 'Success') {
            toast.success('Project Cloned Successfully');
          } else {
            toast.error(message);
          }
        },
        (err) => {
          dispatch({
            type: HOME_CLONE_PROJECT_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Failed cloning project');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissCloneProjectError() {
  return {
    type: HOME_CLONE_PROJECT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CLONE_PROJECT_BEGIN:
      return {
        ...state,
        cloneProjectPending: true,
        cloneProjectError: null,
      };

    case HOME_CLONE_PROJECT_SUCCESS:
      return {
        ...state,
        cloneProjectPending: false,
        cloneProjectError: null,
      };

    case HOME_CLONE_PROJECT_FAILURE:
      return {
        ...state,
        cloneProjectPending: false,
        cloneProjectError: action.data.error,
      };

    case HOME_CLONE_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        cloneProjectError: null,
      };

    default:
      return state;
  }
}
