import {
  LOGIN_RESET_PASSWORD_BEGIN,
  LOGIN_RESET_PASSWORD_SUCCESS,
  LOGIN_RESET_PASSWORD_FAILURE,
  LOGIN_RESET_PASSWORD_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
export function resetPassword(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_RESET_PASSWORD_BEGIN,
    });
    const { data } = args;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/reset-password`,
        data,
        config,
      );
      doRequest.then(
        (res) => {
          if (res.status === 200) {
            window.location.href = '/';
          } else if (res.status === 400) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Password reset failed',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Password reset failed',
            });
          }
          dispatch({
            type: LOGIN_RESET_PASSWORD_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: LOGIN_RESET_PASSWORD_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissResetPasswordError() {
  return {
    type: LOGIN_RESET_PASSWORD_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_RESET_PASSWORD_BEGIN:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordError: null,
      };

    case LOGIN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: null,
      };

    case LOGIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: action.data.error,
      };

    case LOGIN_RESET_PASSWORD_DISMISS_ERROR:
      return {
        ...state,
        resetPasswordError: null,
      };

    default:
      return state;
  }
}
