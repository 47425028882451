import {
  HOME_CLONING_REQUEST_BEGIN,
  HOME_CLONING_REQUEST_SUCCESS,
  HOME_CLONING_REQUEST_FAILURE,
  HOME_CLONING_REQUEST_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';
export function cloningRequest(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CLONING_REQUEST_BEGIN,
    });
    const { formData, projectId } = args;
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/${projectId}/generate-clone-request`,
        formData,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CLONING_REQUEST_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.data.status === 'Success') {
            toast.success('Project Cloning Request send successfully.');
          } else {
            toast.error(res.data.message);
          }
        },

        (err) => {
          dispatch({
            type: HOME_CLONING_REQUEST_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Failed sending request');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissCloningRequestError() {
  return {
    type: HOME_CLONING_REQUEST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CLONING_REQUEST_BEGIN:
      return {
        ...state,
        cloningRequestPending: true,
        cloningRequestError: null,
      };

    case HOME_CLONING_REQUEST_SUCCESS:
      return {
        ...state,
        cloningRequestPending: false,
        cloningRequestError: null,
      };

    case HOME_CLONING_REQUEST_FAILURE:
      return {
        ...state,
        cloningRequestPending: false,
        cloningRequestError: action.data.error,
      };

    case HOME_CLONING_REQUEST_DISMISS_ERROR:
      return {
        ...state,
        cloningRequestError: null,
      };

    default:
      return state;
  }
}
