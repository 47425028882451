import React from 'react';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';

const VideoModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="d-flex justify-content-evenly align-items-center">
        Quick Introduction of Builder
        <Button color="black" onClick={toggle}>
          &times;
        </Button>
      </ModalHeader>
      <ModalBody>
        {isOpen && (
          <iframe
            title="Quick Introduction of Builder"
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/KXqrIYnCRSY"
            frameBorder="8px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
