import React from 'react';
import OrganizationDetail from './OrganizationDetail';

const OrganizationList = ({ organizations, clientDetail }) => {
  if (!organizations || organizations.length === 0) return '';
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="font-weight-bolder">You are member of</h5>
        <hr className="dark horizontal my-4" />
        <div className="table-responsive p-0">
          <table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Organization's Owner
                </th>
                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Projects
                </th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization) => (
                <OrganizationDetail
                  key={organization.uuid}
                  organization={organization}
                  clientDetail={clientDetail}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrganizationList;
