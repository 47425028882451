import React from 'react';
import LineItemDetail from './LineItemDetail';
import CustomPagination from '../../../common/CustomPagination';

const CurrentMonthDetail = ({ lineItems, currentItemPage, totalPages, handleItemPageChange }) => {
  return (
    <div className="card-body">
      <ul className="list-group">
        {lineItems.map((item, index) => (
          <LineItemDetail key={item.uuid} item={item} index={index} />
        ))}
      </ul>
      <CustomPagination
        currentPage={currentItemPage}
        totalPages={totalPages}
        handlePageChange={handleItemPageChange}
      />
    </div>
  );
};

export default CurrentMonthDetail;
