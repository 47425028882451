import React from 'react';
import voca from 'voca';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getProjectTypeLabel } from '../../common/constants';

const ProjectStats = ({ orgClient, project, changeProjectStatus }) => {
  const changeStatusOfProject = () => {
    console.log('project.projectState :>> ', project.projectState);
    let swalMessage = '';
    let icon = '';
    let title = '';
    if (project.projectState === 'FREE') {
      icon = 'success';
      title = 'Are you upgrading?';
      swalMessage = `Upgrading this project to Paid. This is chargeable and enable teams and many more functionality.`;
    } else {
      icon = 'warning';
      title = 'Are you downgrading?';
      swalMessage = `Downgrading this project to Free. This will remove teams and many more functionality form your project.`;
    }

    Swal.fire({
      icon: icon,
      title: title,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      text: swalMessage,
    }).then((result) => {
      if (result.value) {
        changeProjectStatus({
          projectId: project.uuid,
          status: project.projectState === 'FREE' ? 'PAID' : 'FREE',
        });
      }
    });
  };

  return (
    <>
      <div className="card-header">
        <h4>Project Status</h4>
      </div>
      <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
      <div className="card-body">
        {orgClient.subscription === 'BUILDER_FREE' && (
          <p>This account don't have any paid plan. Please upgrade your account.</p>
        )}
        {orgClient.subscription === 'BUILDER_FREE' ? (
          <>
            <Link to={`/accounts`} className="btn btn-sm btn-outline-danger">
              Upgrade Account
            </Link>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-sm btn-outline-dark"
            onClick={changeStatusOfProject}
          >
            Change to {project.projectState === 'FREE' ? 'Paid' : 'Free'}
          </button>
        )}
      </div>
    </>
  );
};

export default ProjectStats;
