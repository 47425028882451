import React from 'react';
import { Link } from 'react-router-dom';
import { getProjectTypeLabel } from '../../common/constants';

const ProjectHosting = ({ orgClient, project }) => {
  const { subscription } = orgClient;
  return (
    <>
      <div className="card-header">
        <h4>Project Cloud Hosting</h4>
      </div>
      <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
      <div className="card-body">
        Current Hosting Plan:
        {subscription === 'UNLIMITED' ? 'Unlimited' : getProjectTypeLabel(project.projectType)}
        {subscription === 'BUILDER_FREE' && (
          <p>Your account is in free plan. Please upgrade your account</p>
        )}
        <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
        {subscription !== 'BUILDER_FREE' && subscription !== 'UNLIMITED' && (
          <Link to={`/${project.uuid}/cloud-hosting`} className="btn btn-sm btn-outline-danger">
            Upgrade
          </Link>
        )}
      </div>
    </>
  );
};

export default ProjectHosting;
