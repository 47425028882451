import axios from 'axios';
import { builderAuthHeader } from '../../common/utils/auth';
import {
  HOME_LOAD_ORG_MEMBERS_BEGIN,
  HOME_LOAD_ORG_MEMBERS_SUCCESS,
  HOME_LOAD_ORG_MEMBERS_FAILURE,
  HOME_LOAD_ORG_MEMBERS_DISMISS_ERROR,
} from './constants';

export function loadOrgMembers(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_LOAD_ORG_MEMBERS_BEGIN,
    });
    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/organization/list`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_LOAD_ORG_MEMBERS_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_LOAD_ORG_MEMBERS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissLoadOrgMembersError() {
  return {
    type: HOME_LOAD_ORG_MEMBERS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_LOAD_ORG_MEMBERS_BEGIN:
      return {
        ...state,
        loadOrgMembersPending: true,
        loadOrgMembersError: null,
      };

    case HOME_LOAD_ORG_MEMBERS_SUCCESS:
      return {
        ...state,
        loadOrgMembersPending: false,
        loadOrgMembersError: null,
        orgMembers: action.data,
      };

    case HOME_LOAD_ORG_MEMBERS_FAILURE:
      return {
        ...state,
        loadOrgMembersPending: false,
        loadOrgMembersError: action.data.error,
      };

    case HOME_LOAD_ORG_MEMBERS_DISMISS_ERROR:
      return {
        ...state,
        loadOrgMembersError: null,
      };

    default:
      return state;
  }
}
