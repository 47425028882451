import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import axios from 'axios'; // Import axios


class ContactUsModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values) {
    const {name, email, Company, phoneNumber } = values;
    const { estimateFeatureId} =this.props;

    const requestBody ={
      name, 
      email,
      company: Company,
      contact: phoneNumber
    }

    try{
      const response = await axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}line-items/${estimateFeatureId}/quote`, requestBody
      );
      console.log("api response", response.data);
      this.props.handleClose(); 
    }
    catch (error) {
      console.error("error submitting form", error);
    }
  }

  render() {
    const { showModal, handleClose, totalCost, estimateFeatureId } = this.props;

    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="card-header w-100 d-flex justify-content-center p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
            <div className="bg-gradient-primary border-radius-lg p-3">
              <h3 className="text-white text-primary mb-0">Let us get back to you</h3>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: '',
              email: '',
              Company: '',
              phoneNumber: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              email: Yup.string().email('Invalid email address').required('Email is required'),
              Company: Yup.string().required('Company name is required'),
              phoneNumber: Yup.string()
                .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
                .required('Phone number is required'),
            })}
            onSubmit={this.handleSubmit} 
          >
            {({ touched, errors, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label text-dark">Name</label>
                      <Field
                        type="text"
                        className="form-control border rounded-3 p-2"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                      />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label text-dark">Work Email</label>
                      <Field
                        type="email"
                        className="form-control border rounded-3 p-2"
                        id="email"
                        name="email"
                        placeholder="name@example.com"
                      />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Company" className="form-label text-dark">Company</label>
                      <Field
                        type="text"
                        className="form-control border rounded-3 p-2"
                        id="Company"
                        name="Company"
                        placeholder="Company Name"
                      />
                      <ErrorMessage name="Company" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="phoneNumber" className="form-label text-dark">Phone Number</label>
                      <div className="input-group">
                        <div className="border rounded-3">
                          <IntlTelInput
                            fieldName="phoneNumber"
                            defaultCountry="us"
                            className="form-control"
                            onPhoneNumberChange={(status, value, countryData, number, id) => {
                              setFieldValue('phoneNumber', value);
                            }}
                            value={values.phoneNumber}
                            preferredCountries={['us']}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            dropdownClassName="dropdown-menu"
                          />
                        </div>
                      </div>
                      <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <Button type="submit" onClick={this.handleSubmit} style={{ backgroundColor: '#1AA6AD' }}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ContactUsModal;
