import React from 'react';
import { AppContainer } from 'react-hot-loader';
import { render } from 'react-dom';
import configStore from './common/store';
import routeConfig from './common/routeConfig';
import Root from './Root';
import './styles/index.scss';

const store = configStore();

function renderApp(app) {
  render(<AppContainer>{app}</AppContainer>, document.getElementById('root'));
}

renderApp(<Root store={store} routeConfig={routeConfig} />);
