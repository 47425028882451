import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import CustomLoader from '../common/CustomLoader';
import { cloneValidationSchema, projectValidationSchema } from '../common/utils/form-validation';
import Layout from './Layout';
import Dropzone from 'react-dropzone';

export class CloneProject extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    showAdvancedSettings: false,
    createdProject: {},
  };

  async componentDidMount() {
    const { actions, match } = this.props;
    const { params } = match;
    const { projectUuid } = params;
    await actions.fetchProject({ projectId: projectUuid });
    await actions.checkCloning({ projectId: projectUuid });
  }

  toggleAdvancedSettings = () => {
    this.setState((prevState) => ({
      showAdvancedSettings: !prevState.showAdvancedSettings,
    }));
  };

  handleDrop = (acceptedFiles, fieldName, setFieldValue) => {
    setFieldValue(fieldName, acceptedFiles[0]);
  };

  renderDropzone = (fieldName, label, setFieldValue, previewUrl) => {
    return (
      <div className="form-group">
        <label className="col-form-label">{label}</label>
        <div className="custom-file">
          <Dropzone
            onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles, fieldName, setFieldValue)}
            accept=".jpg,.jpeg,.gif,.ico,.png"
            maxSize={5242880}
            className="dropzone"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`dropzone-wrapper ${previewUrl ? 'with-preview' : ''}`}
              >
                <input {...getInputProps()} />
                <div>
                  {previewUrl ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-link cancel-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(fieldName, null);
                        }}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      <img src={previewUrl} alt="Preview" className="img-thumbnail" />
                    </>
                  ) : (
                    <>
                      Drag & drop or click to select an image{' '}
                      <i
                        className="fas fa-cloud-upload-alt upload-icon"
                        style={{ fontSize: '30px' }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
          <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />
        </div>
      </div>
    );
  };

  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { actions, history, match, home } = this.props;
      const { params } = match;
      const { projectUuid } = params;
      const { checkCloning } = home;
      const { status, permissionType } = checkCloning || {};
      setSubmitting(true);
      const formData = new FormData();
      formData.append('name', values.projectName);
      formData.append('framework', 'BOOTSTRAP');
      formData.append('isCloneCollectionsData', values.isCloneCollectionsData);
      if (values.favicon) {
        formData.append('favicon', values.favicon);
      }
      if (values.logo) {
        formData.append('projectLogo', values.logo);
      }

      if (status === 'Success') {
        const apiAction =
          permissionType === 'CLONE_WITHOUT_PERMISSION'
            ? actions.cloneProject
            : actions.cloningRequest;
        const apiData = await apiAction({ formData, projectId: projectUuid });
        const { project, status: apiStatus } = apiData.data || {};
        if (apiStatus === 'Success') {
          const redirectPath =
            permissionType === 'CLONE_WITH_PERMISSION'
              ? '/open-builder-clone-with-permission'
              : `/${project.uuid}/open-builder-clone-without-permission`;

          history.push(redirectPath);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { showAdvancedSettings } = this.state;
    const { home } = this.props;
    const { checkCloning, cloneProjectPending, cloningRequestPending, project } = home;
    const { status, permissionType } = checkCloning ? checkCloning : {};
    const { name } = project ? project : '';
    return (
      <Layout currentPage="Clone Project">
        <div className="page-header min-vh-90 mt-4">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <div className=" text-center p-37">
                        <h3 className="font-weight-bolder">Clone {name}</h3>
                        <hr className="dark horizontal my-4" />
                      </div>
                      <div className="card-body" style={{ marginTop: '-35px' }}>
                        <Formik
                          initialValues={{
                            projectName: '',
                            favicon: null,
                            logo: null,
                            isCloneCollectionsData: true,
                          }}
                          validationSchema={projectValidationSchema}
                          onSubmit={this.handleSubmit}
                        >
                          {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                            <Form encType="multipart/form-data">
                              <div className="form-group">
                                <label className="col-form-label">Name</label>
                                <Field
                                  type="text"
                                  name="projectName"
                                  placeholder="Name"
                                  className={`form-control ${
                                    touched.projectName && errors.projectName ? 'is-invalid' : ''
                                  } inputField`}
                                  style={{
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.25rem',
                                    padding: '0.375rem 0.75rem',
                                  }}
                                />
                                {touched.projectName && errors.projectName && (
                                  <div className="invalid-feedback">{errors.projectName}</div>
                                )}
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-outline-info btn-md w-100 mt-4 mb-0"
                                  onClick={this.toggleAdvancedSettings}
                                  style={{
                                    border: '1px solid #17a2b8',
                                    color: '#17a2b8',
                                  }}
                                >
                                  <i className="fas fa-cog " style={{ fontSize: '12px' }}></i>{' '}
                                  Advanced Settings
                                </button>
                              </div>
                              <div className="form-check row mt-3">
                                <div className="col-12">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    name="isCloneCollectionsData"
                                    checked={values.isCloneCollectionsData}
                                    // onChange={(e) =>
                                    //   setFieldValue('isCloneCollectionsData', e.target.checked)
                                    // }
                                    className="form-check-input"
                                    style={{ marginLeft: '-23px' }}
                                  />
                                  <label>Clone Project along with data</label>
                                </div>
                              </div>

                              {showAdvancedSettings && (
                                <>
                                  {this.renderDropzone(
                                    'favicon',
                                    'Favicon',
                                    setFieldValue,
                                    values.favicon && URL.createObjectURL(values.favicon),
                                    touched,
                                    errors,
                                  )}
                                  {this.renderDropzone(
                                    'logo',
                                    'Icon/Logo Image',
                                    setFieldValue,
                                    values.logo && URL.createObjectURL(values.logo),
                                    touched,
                                    errors,
                                  )}
                                </>
                              )}
                              <hr className="form-divider" />
                              <div className="text-center d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-success btn-md text-white mt-4 mb-0"
                                  disabled={isSubmitting || cloningRequestPending}
                                >
                                  <i className="material-icons text-lg position-relative">
                                    file_copy
                                  </i>{' '}
                                  Clone
                                  <CustomLoader
                                    loading={
                                      isSubmitting || cloneProjectPending || cloningRequestPending
                                    }
                                  />
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloneProject);
