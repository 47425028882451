import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot, setConfig } from 'react-hot-loader';
import history from './common/history';
import { PrivateRoute } from './common/PrivateRoute';
import { PublicRoute } from './common/PublicRoute';

setConfig({
  logLevel: process.env.REACT_APP_LOG_LEVEL,
});

function renderRouteConfigV3(routes, contextPath, store) {
  const children = [];

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath, store);
      children.push(
        <Route
          key={newContextPath}
          render={(props) => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      if (item.isPrivate) {
        children.push(
          <PrivateRoute
            key={newContextPath}
            component={item.component}
            path={newContextPath}
            store={store}
            exact
          />,
        );
      } else {
        children.push(
          <PublicRoute
            key={newContextPath}
            component={item.component}
            path={newContextPath}
            store={store}
            exact
          />,
        );
      }
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));
  return <Switch>{children}</Switch>;
}

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    routeConfig: PropTypes.array.isRequired,
  };

  render() {
    const children = renderRouteConfigV3(this.props.routeConfig, '/', this.props.store);
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={history}>{children}</ConnectedRouter>
      </Provider>
    );
  }
}
