import React from 'react';
import HostingPlanDetail from './HostingPlanDetail';
import { projectPriceDetails } from '../../common/utils/price-utils';

const ProjectHostingPlan = ({ project, handleUpgrade, cancelUpgrade }) => {
  const env = process.env.REACT_APP_ENV;

  const showProjectCancellation = () => {
    const { projectType } = project;
    if (projectType !== 'FREE') {
      return (
        <div className="col-xl-4 col-md-4 mb-xl-0 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-3 text-center">Free</h4>
              <div className="text-center">
                <h5 className="d-inline-block">
                  $<span className="price">0</span>
                </h5>
                <h5 className="price-detail-color d-inline-block my-2">/month</h5>
                <p className="text-center">
                  <span className="price-color invisible">(Starts with $50/month)</span>
                </p>
                <div className="d-flex justify-content-center mt-4">
                  <button type="button" className="btn btn-danger" onClick={cancelUpgrade}>
                    <i className="material-icons text-lg position-relative">download</i>
                    Cancel Subscription
                  </button>
                </div>
              </div>
              <hr className="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span className="price-color">View Details</span>&nbsp;
                  <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row mb-6">
      {showProjectCancellation()}
      <div
        className={
          project.projectType !== 'FREE'
            ? 'col-xl-4 col-md-4 mb-xl-0 mb-4'
            : 'col-xl-6 col-md-6 mb-xl-0 mb-4'
        }
      >
        <HostingPlanDetail
          planName="Starter Cloud"
          price={50}
          drapcodePlan="PAID_1"
          stripePlan={projectPriceDetails[env]['PAID_1']}
          projectType={project.projectType}
          handleUpgrade={handleUpgrade}
        />
      </div>
      <div
        className={
          project.projectType !== 'FREE'
            ? 'col-xl-4 col-md-4 mb-xl-0 mb-4'
            : 'col-xl-6 col-md-6 mb-xl-0 mb-4'
        }
      >
        <HostingPlanDetail
          planName="Professional Cloud"
          price={150}
          drapcodePlan="PAID_2"
          stripePlan={projectPriceDetails[env]['PAID_2']}
          projectType={project.projectType}
          handleUpgrade={handleUpgrade}
        />
      </div>
    </div>
  );
};

export default ProjectHostingPlan;
