import React from 'react';
import { OrgPlanOptions } from '../../common/constants';

const PlanDetail = ({
  planName,
  country,
  usdPrice,
  drapcodePlan,
  stripePlan,
  subscription,
  handleUpgrade,
}) => {
  const getPriceAndCurrency = (usdPrice) => {
    return (
      <div>
        $<span className="price">{usdPrice}</span>
      </div>
    );
  };

  const buttonText = () => {
    if (subscription === 'BUILDER_FREE' || subscription === 'BUILDER_1') {
      return 'Upgrade';
    } else if (subscription === 'BUILDER_2' || subscription === 'BUILDER_3') {
      return 'Downgrade';
    }
  };

  const showButtonOrMessage = () => {
    if (subscription === drapcodePlan) {
      const planMessage = OrgPlanOptions.find((plan) => plan.value === subscription);
      return (
        <div>
          <div className="flex align-items-center text-success">
            <i className="material-icons opacity-10 fs-3">check_circle</i> &nbsp;
            <p className="fs-5">You already in {planMessage.label}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-success btn-lg text-white"
            onClick={() => handleUpgrade(drapcodePlan, stripePlan)}
          >
            <i className="material-icons text-lg position-relative">upgrade</i> {buttonText()}
          </button>
        </div>
      );
    }
  };

  return (
    <div className="card card-blog card-plain shadow-lg move-on-hover">
      <div className="card-body p-3">
        <h4 className="mb-3 text-center">{planName}</h4>
        <div className="text-center">
          <h5 className="d-inline-block">{getPriceAndCurrency(usdPrice)}</h5>
          <h5 className="price-detail-color d-inline-block my-2">/month</h5>
          <p className="text-center">
            <span className="price-color invisible">(Starts with $650/month)</span>
          </p>
          {showButtonOrMessage()}
        </div>
        <hr className="dark horizontal my-3" />
        <div className="text-center mb-3">
          <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
            <span className="price-color">View Details</span>&nbsp;
            <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PlanDetail;
