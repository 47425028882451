import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import ClientSidebar from './ClientSidebar';
import DashboardFooter from '../home/Footer';
export class OpenBuilderCp extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions } = this.props;
    await actions.clonePendingRequest();
    await actions.projectCloneStatus();
    await actions.clientPendingRequest();
  }

  render() {
    return (
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-2">
            <ClientSidebar history={this.props.history} />
          </div>
          <div className="col-lg-10">
            <main className="text-center p-5">
              <h2 className="mb-3">Thanks for cloning the project.</h2>
              <h5 style={{ margin: '20px' }}>
                Hey! Cloning is in progress. We have sent a cloning request via email to the project
                owner. Once it gets approved, the cloned project will be added to your dashboard.
              </h5>
            </main>
          </div>
        </div>
        <div style={{ marginLeft: "270px" }}>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenBuilderCp);
