import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as actions from './redux/actions';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';
import CustomLoader from '../common/CustomLoader';
import { loginValidationSchema } from '../common/utils/form-validation';
import DashboardFooter from '../home/Footer';

export class Login extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.title = 'Login | Visually Build Web Applications | No Code ...';
    document.querySelector('body').classList.remove('bg-gray-200');
  }

  handleSubmit = async (values, { resetForm }) => {
    const { actions, history } = this.props;
    const data = {
      password: values.password,
      username: values.email,
    };
    const res = await actions.login({ data });
    if (res.status === 200) {
      window.location.href = '/dashboard';
    }
  };

  render() {
    const { loginPending } = this.props.login;
    return (
      <main className="main-content mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <div className="row">
                <a href="https://drapcode.com/" target="_blank">
                  <div className="col-md-12">
                    <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                  </div>
                </a>
                <div className="col-md-12">
                  <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="font-weight-bolder">Login</h4>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{
                            email: '',
                            password: '',
                            rememberMe: true,
                          }}
                          validationSchema={loginValidationSchema}
                          onSubmit={this.handleSubmit}
                        >
                          {({ touched, errors, isSubmitting }) => (
                            <Form>
                              <div className="input-group input-group-outline mb-3">
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  className={`form-control ${
                                    touched.email && errors.email ? 'is-invalid' : ''
                                  }`}
                                />
                                {touched.email && errors.email && (
                                  <div className="invalid-feedback">{errors.email}</div>
                                )}
                              </div>
                              <div className="input-group input-group-outline mb-3">
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  className={`form-control ${
                                    touched.password && errors.password ? 'is-invalid' : ''
                                  }`}
                                />
                                {touched.password && errors.password && (
                                  <div className="invalid-feedback">{errors.password}</div>
                                )}
                              </div>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                                  disabled={isSubmitting || loginPending}
                                >
                                  Login
                                  <CustomLoader loading={isSubmitting || loginPending} />
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-2 text-sm mx-auto">
                          Don't have an account?&nbsp;
                          <Link to={'/register'} className="text-primary font-weight-bold">
                            Signup Now!
                          </Link>
                        </p>
                        <p className="mb-2 text-sm mx-auto">
                          <Link to={'/forgot-password'} className="text-primary font-weight-bold">
                            Forgot your password?
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fixed-bottom">
          <DashboardFooter />
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
