import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Setting from './Setting';
import TeamMember from './TeamMember';
import Layout from './Layout';
import favicon from '../../assets/img/logos/favicon.png';
import { getProjectTypeLabel } from '../common/constants';

export class ProjectDetail extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    activeContent: 'settings',
  };

  componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions, match } = this.props;
    const { params } = match;
    const { projectUuid } = params;
    actions.fetchProject({ projectId: projectUuid });
    actions.projectActivities({ projectId: projectUuid });
    actions.getVersions({ projectId: projectUuid });
  }

  handleNavItemClick = (content) => {
    this.setState({ activeContent: content });
  };

  renderContent() {
    const { activeContent } = this.state;
    switch (activeContent) {
      case 'settings':
        return <Setting props={this.props} />;

      case 'teamMember':
        return <TeamMember props={this.props} />;

      default:
        return null;
    }
  }

  renderMovingTab = () => {
    const { activeContent } = this.state;
    let translate3dTx = '';
    let movingTabWidth = '';
    switch (activeContent) {
      case 'settings':
        translate3dTx = '0px';
        movingTabWidth = '49.5%';
        break;
      case 'teamMember':
        translate3dTx = '96%';
        movingTabWidth = '49.5%';
        break;
      default:
        translate3dTx = '0px';
        movingTabWidth = '49.5%';
        break;
    }
    return (
      <div
        className="moving-tab nav-link position-absolute"
        style={{
          padding: '0px',
          transition: 'all 0.5s ease 0s',
          transform: `translate3d(${translate3dTx}, 0px, 0px)`,
          width: movingTabWidth,
        }}
      >
        <a
          className="nav-link mb-0 px-0 py-1 active"
          data-bs-toggle="tab"
          role="tab"
          aria-selected="true"
        >
          -
        </a>
      </div>
    );
  };

  renderProjectFavicon = () => {
    const { home } = this.props;
    const { project } = home;
    const { faviconKeyName } = project || '';

    const projectIcon = faviconKeyName
      ? `${process.env.REACT_APP_S3_URL}${faviconKeyName}`
      : favicon;

    return <img src={projectIcon} alt="project_image" className="w-100 border-radius-lg" />;
  };

  render() {
    const { home } = this.props;
    const { project, orgClient } = home;
    const { activeContent } = this.state;
    return (
      <Layout currentPage="Project Details">
        <div className="row mt-5">
          <div className="row gx-4 mb-2">
            <div className="col-auto">
              <div className="avatar position-relative rounded border-radius-on">
                {this.renderProjectFavicon()}
              </div>
            </div>
            <div className="col-auto my-auto">
              <div className="h-100">
                <h5 className="mb-1">{project.name}</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-4">
              <div className="nav-wrapper position-relative end-0">
                <ul className="nav nav-pills nav-fill p-1" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link mb-0 px-0 py-1 ${
                        activeContent === 'settings' ? 'active' : ''
                      }`}
                      onClick={(ev) => this.handleNavItemClick('settings')}
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      <i className="material-icons text-lg position-relative">settings</i>
                      <span className="ms-1">Settings</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link mb-0 px-0 py-1 ${
                        activeContent === 'teamMember' ? 'active' : ''
                      }`}
                      onClick={(ev) => this.handleNavItemClick('teamMember')}
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      <i className="material-icons text-lg position-relative">people</i> Team Member
                    </a>
                  </li>
                  {this.renderMovingTab()}
                </ul>
              </div>
            </div>
          </div>
          {this.renderContent()}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
