import axios from 'axios';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import {
  LOGIN_LOGIN_BEGIN,
  LOGIN_LOGIN_SUCCESS,
  LOGIN_LOGIN_FAILURE,
  LOGIN_LOGIN_DISMISS_ERROR,
  LOGIN_LOGOUT_REQUEST,
} from './constants';

export function login(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOGIN_BEGIN,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}auth`,
        args.data,
        options,
      );
      doRequest.then(
        (res) => {
          if (res.data && res.data.accessToken) {
            const { accessToken, roles, name, uuid, username, organizationCountry } = res.data;
            dispatch({
              type: LOGIN_LOGIN_SUCCESS,
              token: accessToken,
              role: roles[0],
              uuid: uuid,
              username: username,
              name: name,
              country: organizationCountry,
            });

            console.log('organizationCountry', organizationCountry);

            cookie.save('token', accessToken, { maxAge: 10000 * 60, path: '/' });
            cookie.save('role', roles[0], { maxAge: 10000 * 60, path: '/' });
            cookie.save('username', username, { maxAge: 10000 * 60, path: '/' });
            cookie.save('uuid', uuid, { maxAge: 10000 * 60, path: '/' });
            cookie.save('name', name, { maxAge: 10000 * 60, path: '/' });
            cookie.save('country', organizationCountry, { maxAge: 10000 * 60, path: '/' });
          } else {
            dispatch({
              type: LOGIN_LOGIN_FAILURE,
              data: { error: "User or Password don't match" },
            });
          }
          resolve(res);
        },
        (err) => {
          dispatch({
            type: LOGIN_LOGIN_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 400) {
            toast.error('User not found! Please check your email & password.');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissLoginError() {
  return {
    type: LOGIN_LOGIN_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_LOGIN_BEGIN:
      return {
        ...state,
        loginPending: true,
        loginError: null,
      };

    case LOGIN_LOGIN_SUCCESS:
      return {
        ...state,
        loginPending: false,
        loginError: null,
        token: action.token,
        role: action.role,
        uuid: action.uuid,
        username: action.username,
        name: action.name,
        isLoggedIn: true,
        country: action.country,
      };

    case LOGIN_LOGIN_FAILURE:
      return {
        ...state,
        loginPending: false,
        loginError: action.data.error,
      };

    case LOGIN_LOGIN_DISMISS_ERROR:
      return {
        ...state,
        loginError: null,
      };
    case LOGIN_LOGOUT_REQUEST:
      return {
        ...state,
        loginError: null,
        token: '',
        role: '',
        uuid: '',
        username: '',
        name: '',
        isLoggedIn: false,
      };

    default:
      return state;
  }
}
