import {
  HOME_CHANGE_PROJECT_PERMISSION_BEGIN,
  HOME_CHANGE_PROJECT_PERMISSION_SUCCESS,
  HOME_CHANGE_PROJECT_PERMISSION_FAILURE,
  HOME_CHANGE_PROJECT_PERMISSION_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';

export function changeProjectPermission(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CHANGE_PROJECT_PERMISSION_BEGIN,
    });
    const { projectId, data } = args;

    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/${projectId}/change-permission`,
        data,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CHANGE_PROJECT_PERMISSION_SUCCESS,
            data: res,
          });
          resolve(res);
          if (res.status === 200) {
            toast.success('Project Permission Changed Successfully');
          }
        },
        (err) => {
          dispatch({
            type: HOME_CHANGE_PROJECT_PERMISSION_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response && err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Internal server error');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissChangeProjectPermissionError() {
  return {
    type: HOME_CHANGE_PROJECT_PERMISSION_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CHANGE_PROJECT_PERMISSION_BEGIN:
      return {
        ...state,
        changeProjectPermissionPending: true,
        changeProjectPermissionError: null,
      };

    case HOME_CHANGE_PROJECT_PERMISSION_SUCCESS:
      return {
        ...state,
        changeProjectPermissionPending: false,
        changeProjectPermissionError: null,
      };

    case HOME_CHANGE_PROJECT_PERMISSION_FAILURE:
      return {
        ...state,
        changeProjectPermissionPending: false,
        changeProjectPermissionError: action.data.error,
      };

    case HOME_CHANGE_PROJECT_PERMISSION_DISMISS_ERROR:
      return {
        ...state,
        changeProjectPermissionError: null,
      };

    default:
      return state;
  }
}
