import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import * as actions from './redux/actions';
import PlanDetail from './accounts/PlanDetail';
import { orgPriceDetails } from '../common/utils/price-utils';

export class ClientPricingCards extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  handleUpgrade = async (planType, priceId) => {
    try {
      const {
        actions,
        home: { orgClient },
      } = this.props;
      const { subscription } = orgClient;
      if (subscription === 'UNLIMITED') {
        return;
      }
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
      const res = await actions.stripePayment({ priceId, planType });
      if (res && res.data && res.data.id) {
        stripe.redirectToCheckout({ sessionId: res.data.id });
      } else {
        console.error('Invalid API response:', res);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  getCustom = () => {
    return (
      <div className="text-center">
        <h5 className="price">Custom</h5>
      </div>
    );
  };

  render() {
    const { login, home } = this.props;
    const country = login.country;
    const { orgClient } = home;
    const { subscription } = orgClient;
    const env = process.env.REACT_APP_ENV;
    if (subscription === 'UNLIMITED') {
      return 'No need to upgrade';
    }
    return (
      <div className="row mb-6">
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <PlanDetail
            subscription={subscription}
            planName="Professional Plan"
            country={country}
            usdPrice={45}
            drapcodePlan="BUILDER_1"
            stripePlan={orgPriceDetails[env]['BUILDER_1']}
            handleUpgrade={this.handleUpgrade}
          />
        </div>
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <PlanDetail
            subscription={subscription}
            planName="Growth Plan"
            country={country}
            usdPrice={125}
            drapcodePlan="BUILDER_2"
            stripePlan={orgPriceDetails[env]['BUILDER_2']}
            handleUpgrade={this.handleUpgrade}
          />
        </div>
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-3 text-center">Enterprise Plan</h4>
              {this.getCustom()}
              <p className="text-center">
                <span className="price-color">(Starts with $650/month)</span>
              </p>
              <div className="d-flex justify-content-center">
                <a href="https://drapcode.com/contact-us" target="_blank">
                  <button type="button" className="btn btn-success btn-lg text-white">
                    <i className="material-icons">mail</i> Contact Us
                  </button>
                </a>
              </div>
              <hr className="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span className="price-color">View Details</span>&nbsp;
                  <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPricingCards);
