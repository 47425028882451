import {
  LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN,
  LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS,
  LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE,
  LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import cookie from 'react-cookies';
export function confirmClientRegister(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN,
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}clients/confirm/${args.id}`,
        options,
      );
      doRequest.then(
        (res) => {
          if (res.status === 200 && res.data.message === 'success') {
            const { accessToken, roles, name, tokenType, uuid, username, organizationCountry } =
              res.data.data;
            dispatch({
              type: LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS,
              token: accessToken,
              role: roles[0],
              uuid: uuid,
              username: username,
              name: name,
              country: organizationCountry,
            });
            // eslint-disable-next-line no-console
            console.log(`username`, username);
            // eslint-disable-next-line no-console
            console.log(`uuid`, uuid);
            // eslint-disable-next-line no-console
            console.log(`name`, name);

            cookie.save('token', accessToken, { maxAge: 10000 * 60, path: '/' });
            cookie.save('role', roles[0], { maxAge: 10000 * 60, path: '/' });
            cookie.save('username', username, { maxAge: 10000 * 60, path: '/' });
            cookie.save('uuid', uuid, { maxAge: 10000 * 60, path: '/' });
            cookie.save('name', name, { maxAge: 10000 * 60, path: '/' });
            cookie.save('country', organizationCountry, { maxAge: 10000 * 60, path: '/' });
            cookie.save('authentication', tokenType, { maxAge: 10000 * 60, path: '/' });
          }
          resolve(res.data);
        },

        (err) => {
          dispatch({
            type: LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissConfirmClientRegisterError() {
  return {
    type: LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN:
      return {
        ...state,
        confirmClientRegisterPending: true,
        confirmClientRegisterError: null,
      };

    case LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS:
      return {
        ...state,
        confirmClientRegisterPending: false,
        confirmClientRegisterError: null,
        token: action.token,
        role: action.role,
        uuid: action.uuid,
        username: action.username,
        name: action.name,
        isLoggedIn: true,
        country: action.country,
      };

    case LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE:
      return {
        ...state,
        confirmClientRegisterPending: false,
        confirmClientRegisterError: action.data.error,
      };

    case LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR:
      return {
        ...state,
        confirmClientRegisterError: null,
      };

    default:
      return state;
  }
}
