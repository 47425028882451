import axios from 'axios';
import {
  HOME_FETCH_LINE_ITEMS_BEGIN,
  HOME_FETCH_LINE_ITEMS_SUCCESS,
  HOME_FETCH_LINE_ITEMS_FAILURE,
  HOME_FETCH_LINE_ITEMS_DISMISS_ERROR,
} from './constants';
import { builderAuthHeaderWithParams } from '../../common/utils/auth';

export function fetchLineItems(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_FETCH_LINE_ITEMS_BEGIN,
    });

    const { page, size } = args;
    const params = {
      page,
      size,
    };

    const options = builderAuthHeaderWithParams(params);
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}invoices/line-items`,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_FETCH_LINE_ITEMS_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_FETCH_LINE_ITEMS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchLineItemsError() {
  return {
    type: HOME_FETCH_LINE_ITEMS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_FETCH_LINE_ITEMS_BEGIN:
      return {
        ...state,
        fetchLineItemsPending: true,
        fetchLineItemsError: null,
      };

    case HOME_FETCH_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        fetchLineItemsPending: false,
        fetchLineItemsError: null,
        lineItemMetadata: action.data,
      };

    case HOME_FETCH_LINE_ITEMS_FAILURE:
      return {
        ...state,
        fetchLineItemsPending: false,
        fetchLineItemsError: action.data.error,
      };

    case HOME_FETCH_LINE_ITEMS_DISMISS_ERROR:
      return {
        ...state,
        fetchLineItemsError: null,
      };

    default:
      return state;
  }
}
