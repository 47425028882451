import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as actions from './redux/actions';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';
import CustomLoader from '../common/CustomLoader';
import { inviteTeamValidationSchema } from '../common/utils/form-validation';
import DashboardFooter from '../home/Footer';
import { Link } from 'react-router-dom';
export class ForgotPassword extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    showForm: true,
    successMessage: 'Password reset link sent successfully!',
    resetPasswordMessage:
      'Reset password link has been sent to your email. Please check your inbox and click on the link to reset your password.',
  };

  componentDidMount() {
    document.querySelector('body').classList.remove('bg-gray-200');
  }

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    try {
      const { actions } = this.props;
      const data = {
        email: values.email,
      };
      const res = await actions.forgotPassword({ data });
      if (res.status === 200) {
        this.setState({
          successMessage: 'Password reset link sent successfully!',
          showForm: false,
          resetPasswordMessage:
            'Reset password link has been sent to your email. Please check your inbox and click on the link to reset your password.',
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { showForm, successMessage, resetPasswordMessage } = this.state;
    const { login } = this.props;
    const { forgotPasswordPending } = login;
    return (
      <main className="main-content mt-0">
        {showForm ? (
          <section>
            <div className="page-header min-vh-80">
              <div className="container">
                <div className="row">
                  <a href="https://drapcode.com/" target="_blank">
                    <div className="col-md-12">
                      <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                    </div>
                  </a>
                  <div className="col-md-12">
                    <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="font-weight-bolder">Forgot Password</h4>
                        </div>
                        <div className="card-body">
                          <Formik
                            initialValues={{ email: '' }}
                            validationSchema={inviteTeamValidationSchema}
                            onSubmit={this.handleSubmit}
                          >
                            {({ touched, errors, isSubmitting }) => (
                              <Form>
                                <div className="input-group input-group-outline mb-3">
                                  <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email Address*"
                                    className={`form-control ${
                                      touched.email && errors.email ? 'is-invalid' : ''
                                    }`}
                                  />
                                  {touched.email && errors.email && (
                                    <div className="invalid-feedback">{errors.email}</div>
                                  )}
                                </div>
                                <div className=" d-flex justify-content-end">
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary mt-3 w-100"
                                    disabled={isSubmitting || forgotPasswordPending}
                                  >
                                    {' '}
                                    Submit
                                    <CustomLoader loading={isSubmitting || forgotPasswordPending} />
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <main className="main-content mt-0">
            <section>
              <div className="page-header min-vh-100">
                <div className="container">
                  <div className="row">
                    <a href="https://drapcode.com/" target="_blank">
                      <div className="col-md-12">
                        <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                      </div>
                    </a>
                    <div className="col-md-12">
                      <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                        <div className="card">
                          <div className="card-header text-center">
                            <h3 className="font-weight-bolder">{successMessage}</h3>
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="text-center">
                                <h5 className="text-center">{resetPasswordMessage}</h5>
                              </div>
                            </div>
                            <div className="card-footer text-center pt-0 px-lg-2 px-1">
                              <p className="mb-2 text-sm mx-auto">
                                <Link to={'/'} className="text-primary font-weight-bold">
                                  <button className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">
                                    {' '}
                                    Go to login page
                                  </button>
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="fixed-bottom">
              <DashboardFooter />
            </div>
          </main>
        )}
        <div className="fixed-bottom">
          <DashboardFooter />
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
