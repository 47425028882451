import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkWidgetAuthorization } from './utils/check-auth';

export const PrivateRoute = ({ component: Component, store, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const access = checkWidgetAuthorization(store);
      console.log('PrivateRoute access', access);
      if (access.access) {
        if (access.role.includes('ROLE_USER')) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      } else {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }
    }}
  />
);
