import axios from 'axios';
import {
  LOGIN_GET_ESTIMATED_FEATURES_BEGIN,
  LOGIN_GET_ESTIMATED_FEATURES_SUCCESS,
  LOGIN_GET_ESTIMATED_FEATURES_FAILURE,
  LOGIN_GET_ESTIMATED_FEATURES_DISMISS_ERROR,
} from './constants';

 
export function getEstimatedFeatures(args = {}) {
  return (dispatch) => { 
    dispatch({
      type: LOGIN_GET_ESTIMATED_FEATURES_BEGIN,
    });
    const {estimateFeatureId}= args;
    // const token = process.env.REACT_APP_TOKEN;
    const options = { 
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

 
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}line-items/${estimateFeatureId}`,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: LOGIN_GET_ESTIMATED_FEATURES_SUCCESS,
            data: res.data,
          });
          resolve(res);
          // console.log("...........getEstimatedFeatures", res);
        },
         (err) => {
          dispatch({
            type: LOGIN_GET_ESTIMATED_FEATURES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetEstimatedFeaturesError() {
  return {
    type: LOGIN_GET_ESTIMATED_FEATURES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_GET_ESTIMATED_FEATURES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getEstimatedFeaturesPending: true,
        getEstimatedFeaturesError: null,
      };

    case LOGIN_GET_ESTIMATED_FEATURES_SUCCESS:
      // The request is success
      return {
        ...state,
        getEstimatedFeaturesPending: false,
        getEstimatedFeaturesError: null,
        getEstimatedFeatures: action.data,

      };

    case LOGIN_GET_ESTIMATED_FEATURES_FAILURE:
      // The request is failed
      return {
        ...state,
        getEstimatedFeaturesPending: false,
        getEstimatedFeaturesError: action.data.error,
      };

    case LOGIN_GET_ESTIMATED_FEATURES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getEstimatedFeaturesError: null,
      };

    default:
      return state;
  }
}
