import React from 'react';
import { ProjectPlanOptions } from '../../common/constants';

const HostingPlanDetail = ({
  planName,
  price,
  drapcodePlan,
  stripePlan,
  projectType,
  handleUpgrade,
}) => {
  const buttonText = () => {
    if (projectType === 'FREE' || projectType === 'PAID_1') {
      return (
        <>
          <i className="material-icons text-lg position-relative">upload</i> Upgrade
        </>
      );
    } else if (projectType === 'PAID_2') {
      return (
        <>
          <i className="material-icons text-lg position-relative">download</i> Downgrade
        </>
      );
    }
  };
  const showButtonOrMessage = () => {
    if (projectType === drapcodePlan) {
      const planMessage = ProjectPlanOptions.find((plan) => plan.value === projectType);
      return (
        <div>
          <div className="flex align-items-center text-success">
            <i className="material-icons opacity-10 fs-3">check_circle</i> &nbsp;
            <p className="fs-5">You already in {planMessage.label}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-success text-white"
            onClick={() => handleUpgrade(drapcodePlan, stripePlan)}
          >
            {buttonText()}
          </button>
        </div>
      );
    }
  };
  return (
    <div className="card card-blog card-plain shadow-lg move-on-hover">
      <div className="card-body p-3">
        <h4 className="mb-3 text-center">{planName}</h4>
        <div className="text-center">
          <h5 className="d-inline-block">
            $<span className="price">{price}</span>
          </h5>
          <h5 className="price-detail-color d-inline-block my-2">/month</h5>
          <p className="text-center">
            <span className="price-color invisible">(Starts with $50/month)</span>
          </p>
          {showButtonOrMessage()}
        </div>
        <hr className="dark horizontal my-3" />
        <div className="text-center mb-3">
          <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
            <span className="price-color">View Details</span>&nbsp;
            <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HostingPlanDetail;
